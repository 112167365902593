//
// Dropdown
// --------------------------------------------------

.dropdown-menu {
    padding-right: 8px;
    padding-left: 8px;
}

.dropdown-item {
    margin-bottom: 5px;
    border-radius: 4px;
}

.dropdown-header {
    margin-bottom: 5px;
    text-transform: uppercase;
}