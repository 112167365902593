//
// Hero
// --------------------------------------------------

.hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 500px;

    &.hero-sm {
        min-height: 300px;
    }

    &.hero-lg {
        min-height: 800px;
    }

    &-inner {
        flex: 0 0 auto;
        width: 100%;
    }
}

.hero-static {
    min-height: 100vh;
}
