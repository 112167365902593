//
// Borders
// --------------------------------------------------

.border {
    border: 1px solid $body-bg-dark !important;

    &-t { border-top: 1px solid $body-bg-dark !important; }
    &-r { border-right: 1px solid $body-bg-dark !important; }
    &-b { border-bottom: 1px solid $body-bg-dark !important; }
    &-l { border-left: 1px solid $body-bg-dark !important; }
}

.border-light {
    border: 1px solid $body-bg-light !important;

    &-t { border-top: 1px solid $body-bg-light !important; }
    &-r { border-right: 1px solid $body-bg-light !important; }
    &-b { border-bottom: 1px solid $body-bg-light !important; }
    &-l { border-left: 1px solid $body-bg-light !important; }
}

.border-white-op {
    border: 1px solid rgba(255,255,255,.1) !important;

    &-t { border-top: 1px solid rgba(255,255,255,.1) !important; }
    &-r { border-right: 1px solid rgba(255,255,255,.1) !important; }
    &-b { border-bottom: 1px solid rgba(255,255,255,.1) !important; }
    &-l { border-left: 1px solid rgba(255,255,255,.1) !important; }
}

.border-black-op {
    border: 1px solid rgba(0,0,0,.1) !important;

    &-t { border-top: 1px solid rgba(0,0,0,.1) !important; }
    &-r { border-right: 1px solid rgba(0,0,0,.1) !important; }
    &-b { border-bottom: 1px solid rgba(0,0,0,.1) !important; }
    &-l { border-left: 1px solid rgba(0,0,0,.1) !important; }
}

.border-2x { border-width: 2px !important; }
.border-3x { border-width: 3px !important; }
