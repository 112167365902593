//
// Content
// --------------------------------------------------

@mixin content-layout($x, $y, $overflow: visible) {
    margin: 0 auto;
    padding: $y $x 1px;
    width: 100%;
    overflow-x: $overflow;

    .pull-t {
        margin-top: -$y;
    }

    .pull-b {
        margin-bottom: -1px;
    }

    .pull-t-b {
        margin-top: -$y;
        margin-bottom: -1px;
    }

    .pull-r-l {
        margin-right: -$x;
        margin-left: -$x;
    }

    .pull-all {
        margin: (-$y) (-$x) (-1px);
    }

    @at-root {
        #{&}#{&}-full {
            padding-bottom: $y;

            .pull-b,
            .pull-t-b,
            .pull-all {
                margin-bottom: -$y;
            }
        }
    }

    p,
    .push,
    .block,
    .items-push > div {
        margin-bottom: $y;
    }

    .items-push-2x > div {
        margin-bottom: $y*2;
    }

    .items-push-3x > div {
        margin-bottom: $y*3;
    }
}