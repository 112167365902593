//
// Vertical Align
// --------------------------------------------------

.align-parent { position: relative; }

.align-v {
    @include vertical-align(50%, auto, auto, 0, -50%);
}

.align-v-r {
    @include vertical-align(50%, 0, auto, 0, -50%);
}

.align-v-l {
    @include vertical-align(50%, auto, 0, 0, -50%);
}

.align-v-r {
    @include vertical-align(50%, 0, auto, 0, -50%);
}

.align-v-h {
    @include vertical-align(50%, auto, 50%, -50%, -50%);
}