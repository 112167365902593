@import url(~@ng-select/ng-select/themes/default.theme.css);
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #575757;
  text-align: left;
  background-color: #f0f2f5; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 20px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #3f9ce8;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #1568ac;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 5px; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 20px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  line-height: 1.2;
  color: #171717; }

h1, .h1 {
  font-size: 2.57142857rem; }

h2, .h2 {
  font-size: 2.14285714rem; }

h3, .h3 {
  font-size: 1.85714286rem; }

h4, .h4 {
  font-size: 1.57142857rem; }

h5, .h5 {
  font-size: 1.28571429rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.5rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e4e7ed; }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f0f2f5;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%; }

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%; }

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%; }

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%; }

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333333%; }

.offset-2 {
  margin-left: 16.66666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333333%; }

.offset-5 {
  margin-left: 41.66666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333333%; }

.offset-8 {
  margin-left: 66.66666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333333%; }

.offset-11 {
  margin-left: 91.66666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333333%; }
  .offset-sm-2 {
    margin-left: 16.66666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333333%; }
  .offset-sm-5 {
    margin-left: 41.66666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333333%; }
  .offset-sm-8 {
    margin-left: 66.66666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333333%; }
  .offset-sm-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333333%; }
  .offset-md-2 {
    margin-left: 16.66666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333333%; }
  .offset-md-5 {
    margin-left: 41.66666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333333%; }
  .offset-md-8 {
    margin-left: 66.66666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333333%; }
  .offset-md-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333333%; }
  .offset-lg-2 {
    margin-left: 16.66666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333333%; }
  .offset-lg-5 {
    margin-left: 41.66666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333333%; }
  .offset-lg-8 {
    margin-left: 66.66666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333333%; }
  .offset-lg-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333333%; }
  .offset-xl-2 {
    margin-left: 16.66666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333333%; }
  .offset-xl-5 {
    margin-left: 41.66666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333333%; }
  .offset-xl-8 {
    margin-left: 66.66666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333333%; }
  .offset-xl-11 {
    margin-left: 91.66666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 10px;
    vertical-align: top;
    border-top: 1px solid #e4e7ed; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e4e7ed; }
  .table tbody + tbody {
    border-top: 2px solid #e4e7ed; }
  .table .table {
    background-color: #f0f2f5; }

.table-sm th,
.table-sm td {
  padding: 5px; }

.table-bordered {
  border: 1px solid #e4e7ed; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e4e7ed; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c9e3f9; }

.table-hover .table-primary:hover {
  background-color: #b2d7f6; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b2d7f6; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e3f1d4; }

.table-hover .table-success:hover {
  background-color: #d6ebc1; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d6ebc1; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c2eff5; }

.table-hover .table-info:hover {
  background-color: #ace9f1; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #ace9f1; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff0c3; }

.table-hover .table-warning:hover {
  background-color: #ffeaaa; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffeaaa; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbcfce; }

.table-hover .table-danger:hover {
  background-color: #f9b8b6; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f9b8b6; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.04); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.04); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.04); }

.table .thead-dark th {
  color: #e4e7ed;
  background-color: #343a40;
  border-color: #3f474e; }

.table .thead-light th {
  color: #575757;
  background-color: #e4e7ed;
  border-color: #e4e7ed; }

.table-dark {
  color: #e4e7ed;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #3f474e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.1); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.18); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 8px 14px;
  font-size: 1rem;
  line-height: 16px;
  color: #575757;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d4dae3;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #575757;
    background-color: #fff;
    border-color: #97a5ba;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: 34px; }

select.form-control:focus::-ms-value {
  color: #575757;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(8px + 1px);
  padding-bottom: calc(8px + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 16px; }

.col-form-label-lg {
  padding-top: calc(9px + 1px);
  padding-bottom: calc(9px + 1px);
  font-size: 1.28571429rem;
  line-height: 20px; }

.col-form-label-sm {
  padding-top: calc(5px + 1px);
  padding-bottom: calc(5px + 1px);
  font-size: 0.92857143rem;
  line-height: 16px; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0;
  line-height: 16px;
  color: #575757;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 5px 9px;
  font-size: 0.92857143rem;
  line-height: 16px;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: 28px; }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 9px 18px;
  font-size: 1.28571429rem;
  line-height: 20px;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: 40px; }

.form-group {
  margin-bottom: 16px; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #9ccc65; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(156, 204, 101, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #9ccc65; }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #9ccc65; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #9ccc65; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #daecc5; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #b5d98b; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #9ccc65; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.is-valid .form-control {
  border-color: #9ccc65; }

.is-valid .form-check-input ~ .form-check-label {
  color: #9ccc65; }

.is-valid .custom-control-input ~ .custom-control-label {
  color: #9ccc65; }
  .is-valid .custom-control-input ~ .custom-control-label::before {
    background-color: rgba(156, 204, 101, 0.25); }

.is-valid .valid-feedback,
.is-valid .valid-tooltip {
  display: block; }

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #ef5350; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(239, 83, 80, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ef5350; }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #ef5350; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef5350; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #fac6c5; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #f3817f; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef5350; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.is-invalid .form-control {
  border-color: #ef5350; }

.is-invalid .form-check-input ~ .form-check-label {
  color: #ef5350; }

.is-invalid .custom-control-input ~ .custom-control-label {
  color: #ef5350; }
  .is-invalid .custom-control-input ~ .custom-control-label::before {
    background-color: rgba(239, 83, 80, 0.25); }

.is-invalid .invalid-feedback,
.is-invalid .invalid-tooltip {
  display: block; }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 8px 14px;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #3f9ce8;
  border-color: #197ed1; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #197ed1;
    border-color: #1463a3; }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #3f9ce8;
    border-color: #197ed1; }
  .btn-primary:not([disabled]):not(.disabled):active {
    background-color: #3f9ce8;
    border-color: #197ed1; }
  .btn-primary:not([disabled]):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #197ed1;
    background-image: none;
    border-color: #1463a3; }
  .btn-primary.btn-noborder {
    border-color: #3f9ce8; }
    .btn-primary.btn-noborder:hover, .btn-primary.btn-noborder:focus, .btn-primary.btn-noborder.focus {
      border-color: #197ed1; }
    .btn-primary.btn-noborder.disabled, .btn-primary.btn-noborder:disabled, .btn-primary.btn-noborder:not([disabled]):not(.disabled):active, .btn-primary.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: #3f9ce8; }
    .show > .btn-primary.btn-noborder.dropdown-toggle {
      border-color: #197ed1; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #50565c; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #50565c;
    border-color: #383d41; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #6c757d;
    border-color: #50565c; }
  .btn-secondary:not([disabled]):not(.disabled):active {
    background-color: #6c757d;
    border-color: #50565c; }
  .btn-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #50565c;
    background-image: none;
    border-color: #383d41; }
  .btn-secondary.btn-noborder {
    border-color: #6c757d; }
    .btn-secondary.btn-noborder:hover, .btn-secondary.btn-noborder:focus, .btn-secondary.btn-noborder.focus {
      border-color: #50565c; }
    .btn-secondary.btn-noborder.disabled, .btn-secondary.btn-noborder:disabled, .btn-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-secondary.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: #6c757d; }
    .show > .btn-secondary.btn-noborder.dropdown-toggle {
      border-color: #50565c; }

.btn-success {
  color: #fff;
  background-color: #9ccc65;
  border-color: #7eb73d; }
  .btn-success:hover, .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #7eb73d;
    border-color: #649130; }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #9ccc65;
    border-color: #7eb73d; }
  .btn-success:not([disabled]):not(.disabled):active {
    background-color: #9ccc65;
    border-color: #7eb73d; }
  .btn-success:not([disabled]):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    background-color: #7eb73d;
    background-image: none;
    border-color: #649130; }
  .btn-success.btn-noborder {
    border-color: #9ccc65; }
    .btn-success.btn-noborder:hover, .btn-success.btn-noborder:focus, .btn-success.btn-noborder.focus {
      border-color: #7eb73d; }
    .btn-success.btn-noborder.disabled, .btn-success.btn-noborder:disabled, .btn-success.btn-noborder:not([disabled]):not(.disabled):active, .btn-success.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: #9ccc65; }
    .show > .btn-success.btn-noborder.dropdown-toggle {
      border-color: #7eb73d; }

.btn-info {
  color: #fff;
  background-color: #26c6da;
  border-color: #1c97a6; }
  .btn-info:hover, .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #1c97a6;
    border-color: #15707b; }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #26c6da;
    border-color: #1c97a6; }
  .btn-info:not([disabled]):not(.disabled):active {
    background-color: #26c6da;
    border-color: #1c97a6; }
  .btn-info:not([disabled]):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    background-color: #1c97a6;
    background-image: none;
    border-color: #15707b; }
  .btn-info.btn-noborder {
    border-color: #26c6da; }
    .btn-info.btn-noborder:hover, .btn-info.btn-noborder:focus, .btn-info.btn-noborder.focus {
      border-color: #1c97a6; }
    .btn-info.btn-noborder.disabled, .btn-info.btn-noborder:disabled, .btn-info.btn-noborder:not([disabled]):not(.disabled):active, .btn-info.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: #26c6da; }
    .show > .btn-info.btn-noborder.dropdown-toggle {
      border-color: #1c97a6; }

.btn-warning {
  color: #fff;
  background-color: #ffca28;
  border-color: #eab000; }
  .btn-warning:hover, .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #eab000;
    border-color: #b78a00; }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffca28;
    border-color: #eab000; }
  .btn-warning:not([disabled]):not(.disabled):active {
    background-color: #ffca28;
    border-color: #eab000; }
  .btn-warning:not([disabled]):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    background-color: #eab000;
    background-image: none;
    border-color: #b78a00; }
  .btn-warning.btn-noborder {
    border-color: #ffca28; }
    .btn-warning.btn-noborder:hover, .btn-warning.btn-noborder:focus, .btn-warning.btn-noborder.focus {
      border-color: #eab000; }
    .btn-warning.btn-noborder.disabled, .btn-warning.btn-noborder:disabled, .btn-warning.btn-noborder:not([disabled]):not(.disabled):active, .btn-warning.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: #ffca28; }
    .show > .btn-warning.btn-noborder.dropdown-toggle {
      border-color: #eab000; }

.btn-danger {
  color: #fff;
  background-color: #ef5350;
  border-color: #ea1c18; }
  .btn-danger:hover, .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ea1c18;
    border-color: #bd1511; }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #ef5350;
    border-color: #ea1c18; }
  .btn-danger:not([disabled]):not(.disabled):active {
    background-color: #ef5350;
    border-color: #ea1c18; }
  .btn-danger:not([disabled]):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    background-color: #ea1c18;
    background-image: none;
    border-color: #bd1511; }
  .btn-danger.btn-noborder {
    border-color: #ef5350; }
    .btn-danger.btn-noborder:hover, .btn-danger.btn-noborder:focus, .btn-danger.btn-noborder.focus {
      border-color: #ea1c18; }
    .btn-danger.btn-noborder.disabled, .btn-danger.btn-noborder:disabled, .btn-danger.btn-noborder:not([disabled]):not(.disabled):active, .btn-danger.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: #ef5350; }
    .show > .btn-danger.btn-noborder.dropdown-toggle {
      border-color: #ea1c18; }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #d4dae1; }
  .btn-light:hover, .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #d4dae1;
    border-color: #b7c1cb; }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #d4dae1; }
  .btn-light:not([disabled]):not(.disabled):active {
    background-color: #f8f9fa;
    border-color: #d4dae1; }
  .btn-light:not([disabled]):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    background-color: #d4dae1;
    background-image: none;
    border-color: #b7c1cb; }
  .btn-light.btn-noborder {
    border-color: #f8f9fa; }
    .btn-light.btn-noborder:hover, .btn-light.btn-noborder:focus, .btn-light.btn-noborder.focus {
      border-color: #d4dae1; }
    .btn-light.btn-noborder.disabled, .btn-light.btn-noborder:disabled, .btn-light.btn-noborder:not([disabled]):not(.disabled):active, .btn-light.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: #f8f9fa; }
    .show > .btn-light.btn-noborder.dropdown-toggle {
      border-color: #d4dae1; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #191b1e; }
  .btn-dark:hover, .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #191b1e;
    border-color: #020202; }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #191b1e; }
  .btn-dark:not([disabled]):not(.disabled):active {
    background-color: #343a40;
    border-color: #191b1e; }
  .btn-dark:not([disabled]):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    background-color: #191b1e;
    background-image: none;
    border-color: #020202; }
  .btn-dark.btn-noborder {
    border-color: #343a40; }
    .btn-dark.btn-noborder:hover, .btn-dark.btn-noborder:focus, .btn-dark.btn-noborder.focus {
      border-color: #191b1e; }
    .btn-dark.btn-noborder.disabled, .btn-dark.btn-noborder:disabled, .btn-dark.btn-noborder:not([disabled]):not(.disabled):active, .btn-dark.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: #343a40; }
    .show > .btn-dark.btn-noborder.dropdown-toggle {
      border-color: #191b1e; }

.btn-outline-primary {
  color: #3f9ce8;
  background-color: transparent;
  background-image: none;
  border-color: #3f9ce8; }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus {
    color: #fff;
    background-color: #3f9ce8;
    border-color: #3f9ce8; }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #3f9ce8;
    background-color: transparent; }
  .btn-outline-primary:not([disabled]):not(.disabled):active {
    color: #3f9ce8;
    background-color: transparent;
    border-color: #3f9ce8; }
  .btn-outline-primary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f9ce8;
    border-color: #3f9ce8; }
  .btn-outline-primary.btn-noborder {
    border-color: transparent; }
    .btn-outline-primary.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-primary.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not([disabled]):not(.disabled):active {
    color: #6c757d;
    background-color: transparent;
    border-color: #6c757d; }
  .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary.btn-noborder {
    border-color: transparent; }
    .btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: transparent; }

.btn-outline-success {
  color: #9ccc65;
  background-color: transparent;
  background-image: none;
  border-color: #9ccc65; }
  .btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success.focus {
    color: #fff;
    background-color: #9ccc65;
    border-color: #9ccc65; }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #9ccc65;
    background-color: transparent; }
  .btn-outline-success:not([disabled]):not(.disabled):active {
    color: #9ccc65;
    background-color: transparent;
    border-color: #9ccc65; }
  .btn-outline-success:not([disabled]):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #9ccc65;
    border-color: #9ccc65; }
  .btn-outline-success.btn-noborder {
    border-color: transparent; }
    .btn-outline-success.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-success.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: transparent; }

.btn-outline-info {
  color: #26c6da;
  background-color: transparent;
  background-image: none;
  border-color: #26c6da; }
  .btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info.focus {
    color: #fff;
    background-color: #26c6da;
    border-color: #26c6da; }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #26c6da;
    background-color: transparent; }
  .btn-outline-info:not([disabled]):not(.disabled):active {
    color: #26c6da;
    background-color: transparent;
    border-color: #26c6da; }
  .btn-outline-info:not([disabled]):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #26c6da;
    border-color: #26c6da; }
  .btn-outline-info.btn-noborder {
    border-color: transparent; }
    .btn-outline-info.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-info.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: transparent; }

.btn-outline-warning {
  color: #ffca28;
  background-color: transparent;
  background-image: none;
  border-color: #ffca28; }
  .btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning.focus {
    color: #fff;
    background-color: #ffca28;
    border-color: #ffca28; }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffca28;
    background-color: transparent; }
  .btn-outline-warning:not([disabled]):not(.disabled):active {
    color: #ffca28;
    background-color: transparent;
    border-color: #ffca28; }
  .btn-outline-warning:not([disabled]):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffca28;
    border-color: #ffca28; }
  .btn-outline-warning.btn-noborder {
    border-color: transparent; }
    .btn-outline-warning.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-warning.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: transparent; }

.btn-outline-danger {
  color: #ef5350;
  background-color: transparent;
  background-image: none;
  border-color: #ef5350; }
  .btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger.focus {
    color: #fff;
    background-color: #ef5350;
    border-color: #ef5350; }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ef5350;
    background-color: transparent; }
  .btn-outline-danger:not([disabled]):not(.disabled):active {
    color: #ef5350;
    background-color: transparent;
    border-color: #ef5350; }
  .btn-outline-danger:not([disabled]):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ef5350;
    border-color: #ef5350; }
  .btn-outline-danger.btn-noborder {
    border-color: transparent; }
    .btn-outline-danger.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-danger.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light.focus {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not([disabled]):not(.disabled):active {
    color: #f8f9fa;
    background-color: transparent;
    border-color: #f8f9fa; }
  .btn-outline-light:not([disabled]):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light.btn-noborder {
    border-color: transparent; }
    .btn-outline-light.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-light.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: transparent; }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark.focus {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not([disabled]):not(.disabled):active {
    color: #343a40;
    background-color: transparent;
    border-color: #343a40; }
  .btn-outline-dark:not([disabled]):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark.btn-noborder {
    border-color: transparent; }
    .btn-outline-dark.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-dark.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #3f9ce8;
  background-color: transparent; }
  .btn-link:hover {
    color: #1568ac;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 9px 18px;
  font-size: 1.28571429rem;
  line-height: 20px;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 5px 9px;
  font-size: 0.92857143rem;
  line-height: 16px;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-bottom: 0;
  border-left: 0.25em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 8px 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #575757;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ed;
  border-radius: 2px; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0;
  border-right: 0.25em solid transparent;
  border-bottom: 0.25em solid;
  border-left: 0.25em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0.25em solid;
  border-bottom: 0.25em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e4e7ed; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 5px 10px;
  clear: both;
  font-weight: 400;
  color: #575757;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f0f2f5; }
  .dropdown-item.active, .dropdown-item:active {
    color: #575757;
    text-decoration: none;
    background-color: #e4e7ed; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 8px 10px;
  margin-bottom: 0;
  font-size: 0.92857143rem;
  color: #171717;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 5px 10px;
  color: #575757; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 10.5px;
  padding-left: 10.5px; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 6.75px;
  padding-left: 6.75px; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 13.5px;
  padding-left: 13.5px; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::after {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  color: #575757;
  text-align: center;
  white-space: nowrap;
  background-color: #f6f7f9;
  border: 1px solid #d4dae3;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 24px; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 16px; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #3f9ce8; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #f0f2f5, 0 0 0 3px #3f9ce8; }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #deeefb; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: -7.25px;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: -7.25px;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3f9ce8; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #3f9ce8; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(63, 156, 232, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(63, 156, 232, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3f9ce8; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(63, 156, 232, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: 34px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 16px;
  color: #575757;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #d4dae3;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #b1d7f6;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(177, 215, 246, 0.5); }
    .custom-select:focus::-ms-value {
      color: #575757;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: 28px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: 40px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 34px;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 34px;
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #97a5ba;
    box-shadow: none; }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #97a5ba; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 34px;
  padding: 8px 14px;
  line-height: 16px;
  color: #575757;
  background-color: #fff;
  border: 1px solid #d4dae3;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(34px - 1px * 2);
    padding: 8px 14px;
    line-height: 16px;
    color: #575757;
    content: "Browse";
    background-color: #f6f7f9;
    border-left: 1px solid #d4dae3;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #3f9ce8;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #f0f2f5, none; }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #deeefb; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #3f9ce8;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #f0f2f5, none; }
    .custom-range::-moz-range-thumb:active {
      background-color: #deeefb; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #3f9ce8;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #f0f2f5, none; }
    .custom-range::-ms-thumb:active {
      background-color: #deeefb; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #e4e7ed; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e4e7ed; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #575757;
    background-color: #fff;
    border-color: #e4e7ed; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3f9ce8; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 9px 14px;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f0f2f5;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #171717; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 8px 10px;
  margin-left: -1px;
  line-height: 16px;
  color: #171717;
  background-color: #f0f2f5;
  border: 1px solid #f0f2f5; }
  .page-link:hover {
    z-index: 2;
    color: #171717;
    text-decoration: none;
    background-color: #c5cdd8;
    border-color: #c5cdd8; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none; }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #3f9ce8;
  border-color: #3f9ce8; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #fff; }

.pagination-lg .page-link {
  padding: 9px 20px;
  font-size: 1.28571429rem;
  line-height: 20px; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 5px 7px;
  font-size: 0.92857143rem;
  line-height: 16px; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 3px 5px;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #3f9ce8; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1a84da; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #9ccc65; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #83bf3f; }

.badge-info {
  color: #fff;
  background-color: #26c6da; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e9faf; }

.badge-warning {
  color: #fff;
  background-color: #ffca28; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #f4b800; }

.badge-danger {
  color: #fff;
  background-color: #ef5350; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #eb2521; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 24px;
  border: 0 solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 600; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #215179;
  background-color: #d9ebfa;
  border-color: #c9e3f9; }
  .alert-primary hr {
    border-top-color: #b2d7f6; }
  .alert-primary .alert-link {
    color: #163651; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #516a35;
  background-color: #ebf5e0;
  border-color: #e3f1d4; }
  .alert-success hr {
    border-top-color: #d6ebc1; }
  .alert-success .alert-link {
    color: #374824; }

.alert-info {
  color: #146771;
  background-color: #d4f4f8;
  border-color: #c2eff5; }
  .alert-info hr {
    border-top-color: #ace9f1; }
  .alert-info .alert-link {
    color: #0c4046; }

.alert-warning {
  color: #856915;
  background-color: #fff4d4;
  border-color: #fff0c3; }
  .alert-warning hr {
    border-top-color: #ffeaaa; }
  .alert-warning .alert-link {
    color: #59460e; }

.alert-danger {
  color: #7c2b2a;
  background-color: #fcdddc;
  border-color: #fbcfce; }
  .alert-danger hr {
    border-top-color: #f9b8b6; }
  .alert-danger .alert-link {
    color: #561e1d; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 20px 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 20px;
  overflow: hidden;
  font-size: 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3f9ce8;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 20px 20px; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #575757;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #575757;
    text-decoration: none;
    background-color: #f6f7f9; }
  .list-group-item-action:active {
    color: #575757;
    background-color: #e4e7ed; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #e4e7ed; }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #3f9ce8;
    border-color: #3f9ce8; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #215179;
  background-color: #c9e3f9; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #215179;
    background-color: #b2d7f6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #215179;
    border-color: #215179; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #516a35;
  background-color: #e3f1d4; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #516a35;
    background-color: #d6ebc1; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #516a35;
    border-color: #516a35; }

.list-group-item-info {
  color: #146771;
  background-color: #c2eff5; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #146771;
    background-color: #ace9f1; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #146771;
    border-color: #146771; }

.list-group-item-warning {
  color: #856915;
  background-color: #fff0c3; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856915;
    background-color: #ffeaaa; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856915;
    border-color: #856915; }

.list-group-item-danger {
  color: #7c2b2a;
  background-color: #fbcfce; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7c2b2a;
    background-color: #f9b8b6; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7c2b2a;
    border-color: #7c2b2a; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.6; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 0 solid #e4e7ed;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 24px;
    margin: -24px -24px -24px auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 20px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  border-top: 0 solid #e4e7ed; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.92857143rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #343a40; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #343a40; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #343a40; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #343a40; }

.tooltip-inner {
  max-width: 200px;
  padding: 4px 10px;
  color: #fff;
  text-align: center;
  background-color: #343a40;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.92857143rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ed;
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: #e4e7ed; }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: #e4e7ed; }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: #e4e7ed; }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #fff; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: #e4e7ed; }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 12px 10px;
  margin-bottom: 0;
  font-size: 1rem;
  color: #171717;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 10px 10px;
  color: #575757; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #3f9ce8 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1a84da !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #9ccc65 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #83bf3f !important; }

.bg-info {
  background-color: #26c6da !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1e9faf !important; }

.bg-warning {
  background-color: #ffca28 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f4b800 !important; }

.bg-danger {
  background-color: #ef5350 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #eb2521 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #3f9ce8 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #9ccc65 !important; }

.border-info {
  border-color: #26c6da !important; }

.border-warning {
  border-color: #ffca28 !important; }

.border-danger {
  border-color: #ef5350 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714286%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #3f9ce8 !important; }

a.text-primary.link-effect::before {
  background-color: #3f9ce8; }

a.text-primary:hover, a.text-primary:focus {
  color: #1568ac !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary.link-effect::before {
  background-color: #6c757d; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #3d4246 !important; }

.text-success {
  color: #9ccc65 !important; }

a.text-success.link-effect::before {
  background-color: #9ccc65; }

a.text-success:hover, a.text-success:focus {
  color: #699833 !important; }

.text-info {
  color: #26c6da !important; }

a.text-info.link-effect::before {
  background-color: #26c6da; }

a.text-info:hover, a.text-info:focus {
  color: #167784 !important; }

.text-warning {
  color: #ffca28 !important; }

a.text-warning.link-effect::before {
  background-color: #ffca28; }

a.text-warning:hover, a.text-warning:focus {
  color: #c19100 !important; }

.text-danger {
  color: #ef5350 !important; }

a.text-danger.link-effect::before {
  background-color: #ef5350; }

a.text-danger:hover, a.text-danger:focus {
  color: #c71612 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light.link-effect::before {
  background-color: #f8f9fa; }

a.text-light:hover, a.text-light:focus {
  color: #bdc6d0 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark.link-effect::before {
  background-color: #343a40; }

a.text-dark:hover, a.text-dark:focus {
  color: #060708 !important; }

.text-body {
  color: #575757 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; } }

html, body {
  font-size: 14px; }

html.no-focus *:focus {
  outline: none !important; }

strong {
  font-weight: 600; }

a {
  transition: color .12s ease-out; }
  @media (min-width: 576px) {
    a.link-effect {
      position: relative; }
      a.link-effect::before {
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        content: '';
        background-color: #3f9ce8;
        visibility: hidden;
        transform: translateY(6px);
        transform-origin: 50% 50%;
        opacity: 0;
        transition: transform .12s ease-out, opacity .12s ease-out; } }
  a:hover.link-effect::before {
    visibility: visible;
    transform: translateY(0);
    opacity: 1; }

p {
  line-height: 1.6;
  margin-bottom: 24px; }

.nice-copy p,
p.nice-copy {
  line-height: 1.8;
  font-size: 1.14285714rem; }

.nice-copy-story p,
p.nice-copy-story {
  line-height: 1.8;
  font-size: 1.28571429rem;
  color: #626262; }

.nice-copy-story h2,
.nice-copy-story h3,
.nice-copy-story h4 {
  margin-top: 50px; }

.font-size-h1 {
  font-size: 2.57142857rem; }

.font-size-h2 {
  font-size: 2.14285714rem; }

.font-size-h3 {
  font-size: 1.85714286rem; }

.font-size-h4 {
  font-size: 1.57142857rem; }

.font-size-h5 {
  font-size: 1.28571429rem; }

.font-size-h6 {
  font-size: 1rem; }

@media (max-width: 767.98px) {
  .display-1 {
    font-size: 3.429rem; }
  .display-2 {
    font-size: 3.143rem; }
  .display-3 {
    font-size: 2.857rem; }
  .display-4 {
    font-size: 2.57142857rem; }
  h1, .h1, .font-size-h1 {
    font-size: 2.14285714rem; }
  h2, .h2, .font-size-h2 {
    font-size: 1.85714286rem; }
  h3, .h3, .font-size-h3 {
    font-size: 1.57142857rem; }
  h4, .h4, .font-size-h4 {
    font-size: 1.28571429rem; }
  h5, .h5, .font-size-h5,
  h6, .h6, .font-size-h6 {
    font-size: 1rem; } }

.content-heading {
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 8px;
  font-size: 1.42857143rem;
  font-weight: 400;
  line-height: 28px;
  border-bottom: 1px solid #e4e7ed; }
  .content-heading small {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #6c757d; }
  @media (min-width: 768px) {
    .content-heading {
      margin-bottom: 25px;
      padding-top: 40px; }
      .content-heading small {
        margin-top: 0; } }
  .content-heading .dropdown {
    line-height: 1.5; }

small,
.small {
  font-weight: inherit;
  color: #646363; }

.row.gutters-tiny {
  margin-right: -3px;
  margin-left: -3px; }
  .row.gutters-tiny > .col,
  .row.gutters-tiny > [class*="col-"] {
    padding-left: 3px;
    padding-right: 3px; }
  .row.gutters-tiny .push,
  .row.gutters-tiny .block {
    margin-bottom: 6px; }

.row.row-deck > div {
  display: flex;
  align-items: stretch; }
  .row.row-deck > div > .block {
    min-width: 100%; }

.table thead th {
  border-top: none;
  border-bottom: none;
  font-weight: 600;
  text-transform: uppercase; }

.table-vcenter th,
.table-vcenter td {
  vertical-align: middle; }

.table-responsive > .table.table-bordered thead th,
[class*="table-responsive-"] > .table.table-bordered thead th {
  border-top: 1px solid #e4e7ed; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f0f2f5; }

.table-hover .table-active:hover {
  background-color: #e1e5eb; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #e1e5eb; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c8e2f8; }

.table-hover .table-primary:hover {
  background-color: #b1d7f6; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b1d7f6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #ebf5df; }

.table-hover .table-success:hover {
  background-color: #dfefcc; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #dfefcc; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e3f4fc; }

.table-hover .table-info:hover {
  background-color: #ccebfa; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #ccebfa; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf7e6; }

.table-hover .table-warning:hover {
  background-color: #f9f0cf; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f9f0cf; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fae9e8; }

.table-hover .table-danger:hover {
  background-color: #f5d5d3; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f5d5d3; }

.js-table-checkable tbody tr,
.js-table-sections-header > tr {
  cursor: pointer; }

.js-table-sections-header > tr > td:first-child > i {
  transition: transform .2s ease-in-out; }

.js-table-sections-header + tbody {
  display: none; }

.js-table-sections-header.show > tr > td:first-child > i {
  transform: rotate(90deg); }

.js-table-sections-header.show + tbody {
  display: table-row-group; }

label {
  font-weight: 600; }

.form-control,
.input-group-text {
  height: 34px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

select.form-control {
  height: auto; }
  select.form-control:not([size]):not([multiple]) {
    padding-top: 6px;
    padding-bottom: 6px; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  padding-top: 3px;
  padding-bottom: 3px; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  padding-top: 7px;
  padding-bottom: 7px; }

textarea.form-control {
  height: auto;
  padding: 14px; }
  textarea.form-control.form-control-sm, .input-group-sm > textarea.form-control,
  .input-group-sm > .input-group-prepend > textarea.form-control.input-group-text,
  .input-group-sm > .input-group-append > textarea.form-control.input-group-text,
  .input-group-sm > .input-group-prepend > textarea.form-control.btn,
  .input-group-sm > .input-group-append > textarea.form-control.btn {
    padding: 9px; }
  textarea.form-control.form-control-lg, .input-group-lg > textarea.form-control,
  .input-group-lg > .input-group-prepend > textarea.form-control.input-group-text,
  .input-group-lg > .input-group-append > textarea.form-control.input-group-text,
  .input-group-lg > .input-group-prepend > textarea.form-control.btn,
  .input-group-lg > .input-group-append > textarea.form-control.btn {
    padding: 18px; }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: 40px;
  font-size: 1.14285714rem;
  line-height: 20px; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: 28px;
  font-size: 1rem;
  line-height: 16px; }

.col-form-label-lg {
  font-size: 1.14285714rem; }

.col-form-label-sm {
  font-size: 1rem; }

.invalid-feedback {
  font-size: 0.92857143rem; }

.btn {
  height: 34px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600; }
  .btn.btn-square {
    border-radius: 0 !important; }
  .btn.btn-rounded {
    border-radius: 50px !important; }
  .btn .si {
    position: relative;
    top: 1px; }

.btn-sm, .btn-group-sm > .btn,
.btn-group-sm > .btn {
  height: 28px; }

.btn-lg, .btn-group-lg > .btn,
.btn-group-lg > .btn {
  height: 40px; }

.btn.btn-hero {
  padding: 14px 38px;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 3px;
  height: auto; }
  .btn.btn-hero.btn-sm, .btn-group-sm > .btn.btn-hero {
    padding: 11px 34px;
    font-size: 1rem;
    line-height: 16px;
    border-radius: 3px; }
  .btn.btn-hero.btn-lg, .btn-group-lg > .btn.btn-hero {
    padding: 15px 42px;
    font-size: 1.14285714rem;
    line-height: 20px;
    border-radius: 3px; }

.btn.btn-circle {
  padding: 9px 0;
  font-size: 1rem;
  line-height: 1;
  border-radius: inherit;
  min-width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50% !important; }
  .btn.btn-circle.btn-sm, .btn-group-sm > .btn.btn-circle {
    padding: 6px 0;
    font-size: 1rem;
    line-height: 1;
    border-radius: inherit;
    min-width: 28px;
    height: 28px; }
  .btn.btn-circle.btn-lg, .btn-group-lg > .btn.btn-circle {
    padding: 12px 0;
    font-size: 1rem;
    line-height: 1;
    border-radius: inherit;
    min-width: 40px;
    height: 40px; }
  .btn.btn-circle > .si {
    top: 0; }

.btn-secondary {
  color: #212529;
  background-color: #f0f2f5;
  border-color: #cbd2dd; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #cbd2dd;
    border-color: #adb8c8; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #f0f2f5;
    border-color: #cbd2dd; }
  .btn-secondary:not([disabled]):not(.disabled):active {
    background-color: #f0f2f5;
    border-color: #cbd2dd; }
  .btn-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #cbd2dd;
    background-image: none;
    border-color: #adb8c8; }
  .btn-secondary.btn-noborder {
    border-color: #f0f2f5; }
    .btn-secondary.btn-noborder:hover, .btn-secondary.btn-noborder:focus, .btn-secondary.btn-noborder.focus {
      border-color: #cbd2dd; }
    .btn-secondary.btn-noborder.disabled, .btn-secondary.btn-noborder:disabled, .btn-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-secondary.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: #f0f2f5; }
    .show > .btn-secondary.btn-noborder.dropdown-toggle {
      border-color: #cbd2dd; }

.btn-outline-secondary {
  color: #697d9b;
  background-color: transparent;
  background-image: none;
  border-color: #697d9b; }
  .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    color: #fff;
    background-color: #697d9b;
    border-color: #697d9b; }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #697d9b;
    background-color: transparent; }
  .btn-outline-secondary:not([disabled]):not(.disabled):active {
    color: #697d9b;
    background-color: transparent;
    border-color: #697d9b; }
  .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #697d9b;
    border-color: #697d9b; }
  .btn-outline-secondary.btn-noborder {
    border-color: transparent; }
    .btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled).active {
      border-color: transparent; }

.btn-alt-primary {
  color: #125a96;
  background-color: #c8e2f8;
  border-color: #c8e2f8; }
  .btn-alt-primary:hover, .btn-alt-primary:focus, .btn-alt-primary.focus {
    color: #125a96;
    background-color: #9acbf3;
    border-color: #9acbf3;
    box-shadow: none; }
  .btn-alt-primary.disabled, .btn-alt-primary:disabled {
    background-color: #c8e2f8;
    border-color: #c8e2f8; }
  .btn-alt-primary:not([disabled]):not(.disabled):active {
    color: #125a96;
    background-color: #c8e2f8;
    border-color: #c8e2f8; }
  .btn-alt-primary:not([disabled]):not(.disabled).active,
  .show > .btn-alt-primary.dropdown-toggle {
    color: #125a96;
    background-color: #9acbf3;
    border-color: #9acbf3; }

.btn-alt-secondary {
  color: #171717;
  background-color: #e4e7ed;
  border-color: #e4e7ed; }
  .btn-alt-secondary:hover, .btn-alt-secondary:focus, .btn-alt-secondary.focus {
    color: #171717;
    background-color: #c5cdd8;
    border-color: #c5cdd8;
    box-shadow: none; }
  .btn-alt-secondary.disabled, .btn-alt-secondary:disabled {
    background-color: #e4e7ed;
    border-color: #e4e7ed; }
  .btn-alt-secondary:not([disabled]):not(.disabled):active {
    color: #171717;
    background-color: #e4e7ed;
    border-color: #e4e7ed; }
  .btn-alt-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-alt-secondary.dropdown-toggle {
    color: #171717;
    background-color: #c5cdd8;
    border-color: #c5cdd8; }

.btn-alt-success {
  color: #5c852c;
  background-color: #ebf5df;
  border-color: #ebf5df; }
  .btn-alt-success:hover, .btn-alt-success:focus, .btn-alt-success.focus {
    color: #5c852c;
    background-color: #d3e9b8;
    border-color: #d3e9b8;
    box-shadow: none; }
  .btn-alt-success.disabled, .btn-alt-success:disabled {
    background-color: #ebf5df;
    border-color: #ebf5df; }
  .btn-alt-success:not([disabled]):not(.disabled):active {
    color: #5c852c;
    background-color: #ebf5df;
    border-color: #ebf5df; }
  .btn-alt-success:not([disabled]):not(.disabled).active,
  .show > .btn-alt-success.dropdown-toggle {
    color: #5c852c;
    background-color: #d3e9b8;
    border-color: #d3e9b8; }

.btn-alt-info {
  color: #13646e;
  background-color: #e3f4fc;
  border-color: #e3f4fc; }
  .btn-alt-info:hover, .btn-alt-info:focus, .btn-alt-info.focus {
    color: #13646e;
    background-color: #b5e2f7;
    border-color: #b5e2f7;
    box-shadow: none; }
  .btn-alt-info.disabled, .btn-alt-info:disabled {
    background-color: #e3f4fc;
    border-color: #e3f4fc; }
  .btn-alt-info:not([disabled]):not(.disabled):active {
    color: #13646e;
    background-color: #e3f4fc;
    border-color: #e3f4fc; }
  .btn-alt-info:not([disabled]):not(.disabled).active,
  .show > .btn-alt-info.dropdown-toggle {
    color: #13646e;
    background-color: #b5e2f7;
    border-color: #b5e2f7; }

.btn-alt-warning {
  color: #a87e00;
  background-color: #fcf7e6;
  border-color: #fcf7e6; }
  .btn-alt-warning:hover, .btn-alt-warning:focus, .btn-alt-warning.focus {
    color: #a87e00;
    background-color: #f7e8b8;
    border-color: #f7e8b8;
    box-shadow: none; }
  .btn-alt-warning.disabled, .btn-alt-warning:disabled {
    background-color: #fcf7e6;
    border-color: #fcf7e6; }
  .btn-alt-warning:not([disabled]):not(.disabled):active {
    color: #a87e00;
    background-color: #fcf7e6;
    border-color: #fcf7e6; }
  .btn-alt-warning:not([disabled]):not(.disabled).active,
  .show > .btn-alt-warning.dropdown-toggle {
    color: #a87e00;
    background-color: #f7e8b8;
    border-color: #f7e8b8; }

.btn-alt-danger {
  color: #af1310;
  background-color: #fae9e8;
  border-color: #fae9e8; }
  .btn-alt-danger:hover, .btn-alt-danger:focus, .btn-alt-danger.focus {
    color: #af1310;
    background-color: #f1c1be;
    border-color: #f1c1be;
    box-shadow: none; }
  .btn-alt-danger.disabled, .btn-alt-danger:disabled {
    background-color: #fae9e8;
    border-color: #fae9e8; }
  .btn-alt-danger:not([disabled]):not(.disabled):active {
    color: #af1310;
    background-color: #fae9e8;
    border-color: #fae9e8; }
  .btn-alt-danger:not([disabled]):not(.disabled).active,
  .show > .btn-alt-danger.dropdown-toggle {
    color: #af1310;
    background-color: #f1c1be;
    border-color: #f1c1be; }

.btn-dual-secondary {
  color: #1f1f1f;
  background-color: transparent;
  border-color: transparent; }
  .btn-dual-secondary:hover, .btn-dual-secondary:focus, .btn-dual-secondary.focus {
    color: #1f1f1f;
    background-color: #d1d7e1;
    border-color: #d1d7e1;
    box-shadow: none; }
  .btn-dual-secondary.disabled, .btn-dual-secondary:disabled {
    background-color: transparent;
    border-color: transparent; }
  .btn-dual-secondary:not([disabled]):not(.disabled):active {
    color: #1f1f1f;
    background-color: transparent;
    border-color: transparent; }
  .btn-dual-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-dual-secondary.dropdown-toggle {
    color: #1f1f1f;
    background-color: #d1d7e1;
    border-color: #d1d7e1; }

.page-header-inverse #page-header .btn-dual-secondary,
.sidebar-inverse #sidebar .btn-dual-secondary {
  color: #fff;
  background-color: transparent;
  border-color: transparent; }
  .page-header-inverse #page-header .btn-dual-secondary:hover, .page-header-inverse #page-header .btn-dual-secondary:focus, .page-header-inverse #page-header .btn-dual-secondary.focus,
  .sidebar-inverse #sidebar .btn-dual-secondary:hover,
  .sidebar-inverse #sidebar .btn-dual-secondary:focus,
  .sidebar-inverse #sidebar .btn-dual-secondary.focus {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    box-shadow: none; }
  .page-header-inverse #page-header .btn-dual-secondary.disabled, .page-header-inverse #page-header .btn-dual-secondary:disabled,
  .sidebar-inverse #sidebar .btn-dual-secondary.disabled,
  .sidebar-inverse #sidebar .btn-dual-secondary:disabled {
    background-color: transparent;
    border-color: transparent; }
  .page-header-inverse #page-header .btn-dual-secondary:not([disabled]):not(.disabled):active,
  .sidebar-inverse #sidebar .btn-dual-secondary:not([disabled]):not(.disabled):active {
    color: #fff;
    background-color: transparent;
    border-color: transparent; }
  .page-header-inverse #page-header .btn-dual-secondary:not([disabled]):not(.disabled).active,
  .show > .page-header-inverse #page-header .btn-dual-secondary.dropdown-toggle,
  .sidebar-inverse #sidebar .btn-dual-secondary:not([disabled]):not(.disabled).active,
  .show >
  .sidebar-inverse #sidebar .btn-dual-secondary.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }

.fade {
  transition: opacity .15s ease-out; }
  .fade.fade-up {
    transition: opacity .15s ease-out, transform .3s ease-out;
    transform: translateY(100px); }
    .fade.fade-up.show {
      transform: translateY(0); }
  .fade.fade-right {
    transition: opacity .15s ease-out, transform .3s ease-out;
    transform: translateX(-100px); }
    .fade.fade-right.show {
      transform: translateX(0); }
  .fade.fade-left {
    transition: opacity .15s ease-out, transform .3s ease-out;
    transform: translateX(100px); }
    .fade.fade-left.show {
      transform: translateX(0); }

.dropdown-menu {
  padding-right: 8px;
  padding-left: 8px; }

.dropdown-item {
  margin-bottom: 5px;
  border-radius: 4px; }

.dropdown-header {
  margin-bottom: 5px;
  text-transform: uppercase; }

.custom-control-label::before,
.custom-control-label::after {
  top: 3px; }

.css-control {
  position: relative;
  display: inline-block;
  padding: 6px 0;
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer; }
  .css-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
    .css-control-input:focus ~ .css-control-indicator {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.35); }
    .css-control-input ~ .css-control-indicator {
      position: relative;
      display: inline-block;
      margin-top: -3px;
      margin-right: 3px;
      vertical-align: middle; }
      .css-control-input ~ .css-control-indicator::after {
        position: absolute;
        content: ''; }
  .css-control.css-control-sm {
    padding: 3px 0 4px;
    font-size: 1rem; }
  .css-control.css-control-lg {
    padding: 7px 0;
    font-size: 1.14285714rem; }
  .css-control.disabled {
    opacity: .5;
    cursor: not-allowed; }
  .css-control + .css-control {
    margin-left: 10px; }

.css-radio .css-control-input ~ .css-control-indicator {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%; }
  .css-radio .css-control-input ~ .css-control-indicator::after {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .15s ease-out; }

.css-radio .css-control-input:checked ~ .css-control-indicator::after {
  opacity: 1; }

.css-radio:hover .css-control-input ~ .css-control-indicator {
  border-color: #e4e7ed; }

.css-radio.css-control-sm .css-control-input ~ .css-control-indicator {
  width: 16px;
  height: 16px; }

.css-radio.css-control-lg .css-control-input ~ .css-control-indicator {
  width: 26px;
  height: 26px; }

.css-radio.css-control-primary .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #3f9ce8; }

.css-radio.css-control-secondary .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #6c757d; }

.css-radio.css-control-info .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #26c6da; }

.css-radio.css-control-success .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #9ccc65; }

.css-radio.css-control-warning .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #ffca28; }

.css-radio.css-control-danger .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #ef5350; }

.css-checkbox .css-control-input ~ .css-control-indicator {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: background-color .15s; }
  .css-checkbox .css-control-input ~ .css-control-indicator::after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: 'FontAwesome';
    font-size: 10px;
    color: #fff;
    line-height: 18px;
    content: '\f00c';
    text-align: center; }

.css-checkbox:hover .css-control-input ~ .css-control-indicator {
  border-color: #e4e7ed; }

.css-checkbox.css-control-sm .css-control-input ~ .css-control-indicator {
  width: 16px;
  height: 16px; }
  .css-checkbox.css-control-sm .css-control-input ~ .css-control-indicator::after {
    font-size: 8px;
    line-height: 15px; }

.css-checkbox.css-control-lg {
  padding: 6px 0 7px; }
  .css-checkbox.css-control-lg .css-control-input ~ .css-control-indicator {
    width: 28px;
    height: 28px; }
    .css-checkbox.css-control-lg .css-control-input ~ .css-control-indicator::after {
      font-size: 14px;
      line-height: 26px; }

.css-checkbox.css-checkbox-rounded .css-control-input ~ .css-control-indicator {
  border-radius: 2px; }

.css-checkbox.css-control-primary .css-control-input:checked ~ .css-control-indicator {
  background-color: #3f9ce8;
  border-color: #1776c3; }

.css-checkbox.css-control-secondary .css-control-input:checked ~ .css-control-indicator {
  background-color: #6c757d;
  border-color: #494f54; }

.css-checkbox.css-control-info .css-control-input:checked ~ .css-control-indicator {
  background-color: #26c6da;
  border-color: #1a8b99; }

.css-checkbox.css-control-success .css-control-input:checked ~ .css-control-indicator {
  background-color: #9ccc65;
  border-color: #76ac39; }

.css-checkbox.css-control-warning .css-control-input:checked ~ .css-control-indicator {
  background-color: #ffca28;
  border-color: #dba500; }

.css-checkbox.css-control-danger .css-control-input:checked ~ .css-control-indicator {
  background-color: #ef5350;
  border-color: #de1814; }

.css-switch {
  padding: 3px 0; }
  .css-switch .css-control-input ~ .css-control-indicator {
    width: 51px;
    height: 30px;
    background-color: #eee;
    border-radius: 30px;
    transition: background-color .3s; }
    .css-switch .css-control-input ~ .css-control-indicator::after {
      top: 2px;
      bottom: 2px;
      left: 2px;
      width: 26px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
      transition: transform .15s ease-out; }
  .css-switch .css-control-input:checked ~ .css-control-indicator {
    background-color: #ddd; }
    .css-switch .css-control-input:checked ~ .css-control-indicator::after {
      box-shadow: -2px 0 3px rgba(0, 0, 0, 0.3);
      transform: translateX(20px); }
  .css-switch.css-control-sm {
    padding: 3px 0; }
    .css-switch.css-control-sm .css-control-input ~ .css-control-indicator {
      width: 36px;
      height: 20px; }
      .css-switch.css-control-sm .css-control-input ~ .css-control-indicator::after {
        width: 16px; }
    .css-switch.css-control-sm .css-control-input:checked ~ .css-control-indicator::after {
      transform: translateX(15px); }
  .css-switch.css-control-lg {
    padding: 4px 0; }
    .css-switch.css-control-lg .css-control-input ~ .css-control-indicator {
      width: 65px;
      height: 34px; }
      .css-switch.css-control-lg .css-control-input ~ .css-control-indicator::after {
        width: 30px; }
    .css-switch.css-control-lg .css-control-input:checked ~ .css-control-indicator::after {
      transform: translateX(30px); }
  .css-switch.css-switch-square .css-control-input ~ .css-control-indicator,
  .css-switch.css-switch-square .css-control-input ~ .css-control-indicator::after {
    border-radius: 0; }
  .css-switch.css-control-primary .css-control-input:checked ~ .css-control-indicator {
    background-color: #3f9ce8; }
  .css-switch.css-control-secondary .css-control-input:checked ~ .css-control-indicator {
    background-color: #6c757d; }
  .css-switch.css-control-info .css-control-input:checked ~ .css-control-indicator {
    background-color: #26c6da; }
  .css-switch.css-control-success .css-control-input:checked ~ .css-control-indicator {
    background-color: #9ccc65; }
  .css-switch.css-control-warning .css-control-input:checked ~ .css-control-indicator {
    background-color: #ffca28; }
  .css-switch.css-control-danger .css-control-input:checked ~ .css-control-indicator {
    background-color: #ef5350; }

.nav-link {
  color: #575757;
  font-weight: 600; }
  .nav-link:hover, .nav-link:focus {
    color: #3f9ce8; }

.nav-tabs-block {
  background-color: #f6f7f9;
  border-bottom: none; }
  .nav-tabs-block .nav-item {
    margin-bottom: 0; }
  .nav-tabs-block .nav-link {
    padding-top: 12px;
    padding-bottom: 12px;
    border-color: transparent;
    border-radius: 0; }
    .nav-tabs-block .nav-link:hover, .nav-tabs-block .nav-link:focus {
      color: #3f9ce8;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs-block .nav-link.active,
  .nav-tabs-block .nav-item.show .nav-link {
    color: #575757;
    background-color: #fff;
    border-color: transparent; }

.nav-tabs-alt {
  background-color: transparent;
  border-bottom-color: #e4e7ed; }
  .nav-tabs-alt .nav-item {
    margin-bottom: -3px; }
  .nav-tabs-alt .nav-link {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: transparent;
    border-color: transparent; }
    .nav-tabs-alt .nav-link:hover, .nav-tabs-alt .nav-link:focus {
      color: #3f9ce8;
      background-color: transparent;
      border-color: transparent;
      box-shadow: inset 0 -2px #3f9ce8; }
  .nav-tabs-alt .nav-link.active,
  .nav-tabs-alt .nav-item.show .nav-link {
    background-color: transparent;
    border-color: transparent;
    box-shadow: inset 0 -2px #3f9ce8; }

.nav-users {
  margin: 0;
  padding: 0;
  list-style: none; }
  .nav-users > li:last-child > a {
    border-bottom: none; }
  .nav-users a {
    position: relative;
    padding: 12px 8px 8px 71px;
    display: block;
    min-height: 62px;
    font-weight: 600;
    border-bottom: 1px solid #f0f2f5; }
    .nav-users a > img {
      position: absolute;
      left: 12px;
      top: 10px;
      width: 42px;
      height: 42px;
      border-radius: 50%; }
    .nav-users a > i {
      position: absolute;
      left: 40px;
      top: 40px;
      display: inline-block;
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      background-color: #fff;
      border-radius: 50%; }
    .nav-users a:hover {
      background-color: #f6f7f9; }

.breadcrumb-item + .breadcrumb-item::before {
  font-family: 'FontAwesome';
  content: '\f105'; }

.page-link {
  height: 34px;
  line-height: 16px;
  font-size: 1rem;
  font-weight: 600; }
  .page-link:focus {
    color: #171717;
    background-color: #e4e7ed;
    border-color: #e4e7ed; }
  .pagination-lg .page-link {
    height: 40px;
    line-height: 20px;
    font-size: 1.14285714rem; }
  .pagination-sm .page-link {
    height: 28px;
    line-height: 16px;
    font-size: 1rem; }

.badge-secondary {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.33); }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.33); }

.modal-content {
  border-radius: 0; }

.modal-header {
  padding-top: 16px;
  padding-bottom: 16px; }

.modal-footer {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-width: 1px; }

.modal-dialog.modal-dialog-top {
  margin-top: 0 !important; }

.modal.fade .modal-dialog {
  transition-duration: .35s; }
  .modal.fade .modal-dialog.modal-dialog-popin {
    transform: scale(1.2, 1.2); }
  .modal.fade .modal-dialog.modal-dialog-popout {
    transform: scale(0.8, 0.8); }
  .modal.fade .modal-dialog.modal-dialog-slideup {
    transform: translate(0, 25%); }
  .modal.fade .modal-dialog.modal-dialog-slideright {
    transform: translate(-25%, 0); }
  .modal.fade .modal-dialog.modal-dialog-slideleft {
    transform: translate(25%, 0); }
  .modal.fade .modal-dialog.modal-dialog-fromright {
    transform: translateX(25%) rotate(15deg) scale(0.8); }
  .modal.fade .modal-dialog.modal-dialog-fromleft {
    transform: translateX(-25%) rotate(-15deg) scale(0.8); }

.modal.show .modal-dialog.modal-dialog-popin, .modal.show .modal-dialog.modal-dialog-popout {
  transform: scale(1, 1); }

.modal.show .modal-dialog.modal-dialog-slideup, .modal.show .modal-dialog.modal-dialog-slideright, .modal.show .modal-dialog.modal-dialog-slideleft {
  transform: translate(0, 0); }

.modal.show .modal-dialog.modal-dialog-fromright, .modal.show .modal-dialog.modal-dialog-fromleft {
  transform: translateX(0) rotate(0) scale(1); }

.popover-header {
  padding-bottom: 1px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-bottom: none; }

#page-container {
  margin: 0 auto;
  width: 100%;
  min-width: 320px; }

#main-container,
#page-footer {
  overflow-x: hidden; }

.content {
  margin: 0 auto;
  padding: 12px 12px 1px;
  width: 100%;
  overflow-x: visible; }
  .content .pull-t {
    margin-top: -12px; }
  .content .pull-b {
    margin-bottom: -1px; }
  .content .pull-t-b {
    margin-top: -12px;
    margin-bottom: -1px; }
  .content .pull-r-l {
    margin-right: -12px;
    margin-left: -12px; }
  .content .pull-all {
    margin: -12px -12px -1px; }
  .content.content-full {
    padding-bottom: 12px; }
    .content.content-full .pull-b,
    .content.content-full .pull-t-b,
    .content.content-full .pull-all {
      margin-bottom: -12px; }
  .content p,
  .content .push,
  .content .block,
  .content .items-push > div {
    margin-bottom: 12px; }
  .content .items-push-2x > div {
    margin-bottom: 24px; }
  .content .items-push-3x > div {
    margin-bottom: 36px; }
  @media (min-width: 768px) {
    .content {
      margin: 0 auto;
      padding: 24px 24px 1px;
      width: 100%;
      overflow-x: visible; }
      .content .pull-t {
        margin-top: -24px; }
      .content .pull-b {
        margin-bottom: -1px; }
      .content .pull-t-b {
        margin-top: -24px;
        margin-bottom: -1px; }
      .content .pull-r-l {
        margin-right: -24px;
        margin-left: -24px; }
      .content .pull-all {
        margin: -24px -24px -1px; }
      .content.content-full {
        padding-bottom: 24px; }
        .content.content-full .pull-b,
        .content.content-full .pull-t-b,
        .content.content-full .pull-all {
          margin-bottom: -24px; }
      .content p,
      .content .push,
      .content .block,
      .content .items-push > div {
        margin-bottom: 24px; }
      .content .items-push-2x > div {
        margin-bottom: 48px; }
      .content .items-push-3x > div {
        margin-bottom: 72px; } }
  @media (min-width: 1200px) {
    .content.content-narrow {
      max-width: 92%; } }
  .content.content-boxed {
    max-width: 1200px; }

.content-side {
  margin: 0 auto;
  padding: 18px 18px 1px;
  width: 100%;
  overflow-x: hidden; }
  .content-side .pull-t {
    margin-top: -18px; }
  .content-side .pull-b {
    margin-bottom: -1px; }
  .content-side .pull-t-b {
    margin-top: -18px;
    margin-bottom: -1px; }
  .content-side .pull-r-l {
    margin-right: -18px;
    margin-left: -18px; }
  .content-side .pull-all {
    margin: -18px -18px -1px; }
  .content-side.content-side-full {
    padding-bottom: 18px; }
    .content-side.content-side-full .pull-b,
    .content-side.content-side-full .pull-t-b,
    .content-side.content-side-full .pull-all {
      margin-bottom: -18px; }
  .content-side p,
  .content-side .push,
  .content-side .block,
  .content-side .items-push > div {
    margin-bottom: 18px; }
  .content-side .items-push-2x > div {
    margin-bottom: 36px; }
  .content-side .items-push-3x > div {
    margin-bottom: 54px; }
  .content-side-user {
    height: 136px;
    background-color: #f6f7f9;
    overflow: hidden; }

.content.content-top {
  padding-top: 80px; }
  @media (min-width: 992px) {
    .content.content-top {
      padding-top: 92px; } }

.section-top {
  padding-top: 68px; }

#page-header {
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-color: #fff; }

.overlay-header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  transition: transform .2s ease-in-out, opacity .2s ease-in-out;
  transform: translateY(-100%);
  will-change: opacity, transform; }
  .overlay-header.show {
    opacity: 1;
    transform: translateY(0); }

.content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 17px 18px;
  height: 68px; }
  .content-header.content-header-fullrow {
    flex-direction: column;
    align-items: stretch; }
  .content-header-section {
    flex: 0 0 auto; }
    .content-header-section.content-header-section-wrap {
      flex: 1 1 auto; }
  .content-header-item {
    display: inline-block;
    height: 34px;
    line-height: 34px;
    vertical-align: middle; }
    .content-header-item-align {
      vertical-align: middle; }
  .content-header-logo {
    height: 34px; }
  #page-header > .content-header {
    padding-left: 12px;
    padding-right: 12px; }
    @media (min-width: 768px) {
      #page-header > .content-header {
        padding-left: 24px;
        padding-right: 24px; } }

.sidebar-mini-visible,
.sidebar-mini-visible-b {
  display: none; }

.sidebar-mini-show {
  opacity: 0; }

.sidebar-mini-show,
.sidebar-mini-hide {
  transition: opacity 0.26s cubic-bezier(0.47, 0, 0.745, 0.715); }

.sidebar-mini-show.sidebar-mini-notrans,
.sidebar-mini-hide.sidebar-mini-notrans {
  transition: none; }

#sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1033;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  transform: translateX(-100%) translateY(0) translateZ(0);
  -webkit-overflow-scrolling: touch;
  will-change: transform; }
  .side-trans-enabled #sidebar {
    transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715); }
  .sidebar-r #sidebar {
    right: 0;
    left: auto;
    transform: translateX(100%) translateY(0) translateZ(0); }
  @media (max-width: 991.98px) {
    .sidebar-o-xs #sidebar {
      transform: translateX(0) translateY(0) translateZ(0); } }
  @media (min-width: 992px) {
    #sidebar {
      width: 220px; }
      .sidebar-o #sidebar {
        transform: translateX(0) translateY(0) translateZ(0); }
      .sidebar-o.sidebar-mini #sidebar {
        overflow-x: hidden;
        transform: translateX(-166px) translateY(0) translateZ(0);
        transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715); }
      .sidebar-r.sidebar-o.sidebar-mini #sidebar {
        transform: translateX(166px) translateY(0) translateZ(0); }
      .sidebar-o.sidebar-mini #sidebar .sidebar-content {
        width: 220px;
        transform: translateX(166px) translateY(0) translateZ(0);
        transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
        will-change: transform; }
      .sidebar-o.sidebar-mini #sidebar:hover,
      .sidebar-o.sidebar-mini #sidebar:hover .sidebar-content,
      .sidebar-r.sidebar-o.sidebar-mini #sidebar .sidebar-content {
        transform: translateX(0) translateY(0) translateZ(0); }
      .sidebar-o.sidebar-mini #sidebar .sidebar-mini-hide {
        opacity: 0; }
      .sidebar-o.sidebar-mini #sidebar .sidebar-mini-show {
        opacity: 1; }
      .sidebar-o.sidebar-mini #sidebar .sidebar-mini-hidden,
      .sidebar-o.sidebar-mini #sidebar .sidebar-mini-hidden-b {
        display: none; }
      .sidebar-o.sidebar-mini #sidebar .sidebar-mini-visible {
        display: inline-block; }
      .sidebar-o.sidebar-mini #sidebar .sidebar-mini-visible-b {
        display: block; }
      .sidebar-o.sidebar-mini #sidebar .nav-main > li.open > ul {
        display: none; }
      .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hide {
        opacity: 1; }
      .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-show {
        opacity: 0; }
      .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hidden {
        display: inline-block; }
      .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hidden-b {
        display: block; }
      .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-visible,
      .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-visible-b {
        display: none;
        opacity: 0; }
      .sidebar-o.sidebar-mini #sidebar:hover .nav-main > li.open > ul {
        display: block; } }

#side-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1033;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  transform: translateX(100%) translateY(0) translateZ(0);
  -webkit-overflow-scrolling: touch;
  will-change: transform; }
  .side-trans-enabled #side-overlay {
    transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715); }
  .sidebar-r #side-overlay {
    right: auto;
    left: 0;
    transform: translateX(-100%) translateY(0) translateZ(0); }
  .side-overlay-o #side-overlay {
    transform: translateX(0) translateY(0) translateZ(0); }
  @media (min-width: 992px) {
    #side-overlay {
      width: 320px;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
      transform: translateX(110%) translateY(0) translateZ(0); }
      .sidebar-r #side-overlay {
        transform: translateX(-110%) translateY(0) translateZ(0); }
      .side-overlay-hover #side-overlay {
        transform: translateX(302px) translateY(0) translateZ(0); }
      .sidebar-r.side-overlay-hover #side-overlay {
        transform: translateX(-302px) translateY(0) translateZ(0); }
      .side-overlay-hover #side-overlay:hover,
      .side-overlay-o #side-overlay,
      .side-overlay-o.side-overlay-hover #side-overlay {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        transform: translateX(0) translateY(0) translateZ(0); } }

@media (min-width: 1200px) {
  #page-container.main-content-narrow > #page-header .content-header,
  #page-container.main-content-narrow > #page-header .content,
  #page-container.main-content-narrow > #main-container .content,
  #page-container.main-content-narrow > #page-footer .content {
    max-width: 92%; } }

#page-container.main-content-boxed > #page-header .content-header,
#page-container.main-content-boxed > #page-header .content,
#page-container.main-content-boxed > #main-container .content,
#page-container.main-content-boxed > #page-footer .content {
  max-width: 1200px; }

@media (min-width: 992px) {
  #page-container.page-header-modern #page-header {
    background-color: #f0f2f5;
    box-shadow: none !important; }
    #page-container.page-header-modern #page-header > .content-header {
      padding-top: 32px;
      padding-bottom: 2px; } }

#page-container.page-header-inverse #page-header {
  color: #cbd2dd;
  background-color: #343a40; }

#page-container.page-header-glass #page-header {
  position: absolute;
  background-color: transparent; }

#page-container.page-header-glass.page-header-fixed #page-header {
  transition: background-color .2s linear;
  will-change: background-color;
  box-shadow: none; }

#page-container.page-header-glass.page-header-fixed.page-header-scroll #page-header {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02); }

#page-container.page-header-glass.page-header-fixed.page-header-scroll.page-header-inverse #page-header {
  background-color: #343a40;
  box-shadow: none; }

#page-container.page-header-glass #main-container,
#page-container.page-header-glass.page-header-fixed #main-container {
  padding-top: 0; }

#page-container.page-header-fixed #page-header {
  position: fixed;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02); }
  #page-container.page-header-fixed #page-header > .content-header {
    padding-top: 17px;
    padding-bottom: 17px; }

#page-container.page-header-fixed.page-header-inverse #page-header {
  box-shadow: none; }

#page-container.page-header-fixed #main-container {
  padding-top: 68px; }

#page-container.page-header-fixed #page-header, #page-container.page-header-glass #page-header {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  min-width: 320px;
  max-width: 100%;
  width: auto; }

@media (min-width: 992px) {
  #page-container.page-header-fixed.sidebar-o #page-header, #page-container.page-header-glass.sidebar-o #page-header {
    left: 220px; }
  #page-container.page-header-fixed.sidebar-r.sidebar-o #page-header, #page-container.page-header-glass.sidebar-r.sidebar-o #page-header {
    right: 220px;
    left: 0; }
  #page-container.page-header-fixed.sidebar-o.sidebar-mini #page-header, #page-container.page-header-glass.sidebar-o.sidebar-mini #page-header {
    left: 54px; }
  #page-container.page-header-fixed.sidebar-r.sidebar-o.sidebar-mini #page-header, #page-container.page-header-glass.sidebar-r.sidebar-o.sidebar-mini #page-header {
    right: 54px;
    left: 0; } }

@media (min-width: 992px) {
  #page-container.page-header-modern #sidebar {
    box-shadow: 5px 0 10px #e4e7ed; } }

#page-container.sidebar-inverse #sidebar {
  color: #e4e7ed;
  background-color: #343a40;
  box-shadow: none; }
  #page-container.sidebar-inverse #sidebar .content-side-user {
    background-color: #2d3238; }

@media (min-width: 992px) {
  #page-container.sidebar-o {
    padding-left: 220px; }
  #page-container.sidebar-r.sidebar-o {
    padding-right: 220px;
    padding-left: 0; }
  #page-container.sidebar-o.sidebar-mini {
    padding-left: 54px; }
  #page-container.sidebar-r.sidebar-o.sidebar-mini {
    padding-right: 54px;
    padding-left: 0; }
  #page-container.side-scroll #sidebar,
  #page-container.side-scroll #side-overlay {
    overflow-y: hidden; } }

.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 500px; }
  .hero.hero-sm {
    min-height: 300px; }
  .hero.hero-lg {
    min-height: 800px; }
  .hero-inner {
    flex: 0 0 auto;
    width: 100%; }

.hero-static {
  min-height: 100vh; }

.block {
  margin-bottom: 24px;
  background-color: #fff;
  box-shadow: 0 1px 1px #e4e7ed; }
  .block .block,
  .content-side .block {
    box-shadow: none; }

.block-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  transition: opacity .2s ease-out; }
  .block-header.block-header-rtl {
    flex-direction: row-reverse; }
    .block-header.block-header-rtl .block-title {
      text-align: right; }
    .block-header.block-header-rtl .block-options {
      padding-right: 10px;
      padding-left: 0; }
  .block-header-default {
    background-color: #f6f7f9; }

.block-title {
  flex: 1 1 auto;
  min-height: 28px;
  margin: 0;
  font-size: 1.14285714rem;
  font-weight: 400;
  line-height: 28px; }
  .block-title small {
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
    color: #646363; }

.block-content {
  transition: opacity .2s ease-out;
  margin: 0 auto;
  padding: 20px 20px 1px;
  width: 100%;
  overflow-x: visible; }
  .block-content .pull-t {
    margin-top: -20px; }
  .block-content .pull-b {
    margin-bottom: -1px; }
  .block-content .pull-t-b {
    margin-top: -20px;
    margin-bottom: -1px; }
  .block-content .pull-r-l {
    margin-right: -20px;
    margin-left: -20px; }
  .block-content .pull-all {
    margin: -20px -20px -1px; }
  .block-content.block-content-full {
    padding-bottom: 20px; }
    .block-content.block-content-full .pull-b,
    .block-content.block-content-full .pull-t-b,
    .block-content.block-content-full .pull-all {
      margin-bottom: -20px; }
  .block-content p,
  .block-content .push,
  .block-content .block,
  .block-content .items-push > div {
    margin-bottom: 20px; }
  .block-content .items-push-2x > div {
    margin-bottom: 40px; }
  .block-content .items-push-3x > div {
    margin-bottom: 60px; }
  .block-content.block-content-sm {
    padding-top: 10px; }
    .block-content.block-content-sm .pull-t,
    .block-content.block-content-sm .pull-t-b,
    .block-content.block-content-sm .pull-all {
      margin-top: -10px; }
    .block-content.block-content-sm.block-content-full {
      padding-bottom: 10px; }
      .block-content.block-content-sm.block-content-full .pull-b,
      .block-content.block-content-sm.block-content-full .pull-t-b,
      .block-content.block-content-sm.block-content-full .pull-all {
        margin-bottom: -10px; }

.block-table {
  display: table;
  border-collapse: collapse;
  width: 100%; }
  .block-table .block-row {
    display: table-row; }
  .block-table .block-cell {
    display: table-cell;
    padding: 20px;
    vertical-align: middle; }

.block.block-bordered {
  border: 1px solid #e4e7ed;
  box-shadow: none; }

.block.block-rounded {
  border-radius: 4px; }
  .block.block-rounded > .block-header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .block.block-rounded > .block-header:last-child {
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px; }
  .block.block-rounded > .block-content:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  .block.block-rounded > .block-content:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }

.block.block-themed > .block-header {
  border-bottom: none;
  color: #fff;
  background-color: #3f9ce8; }
  .block.block-themed > .block-header > .block-title {
    color: rgba(255, 255, 255, 0.9); }
    .block.block-themed > .block-header > .block-title small {
      color: rgba(255, 255, 255, 0.7); }

.block.block-transparent {
  background-color: transparent;
  box-shadow: none; }

.block.block-shadow {
  box-shadow: 0 15px 30px rgba(33, 37, 41, 0.05); }

.block.block-mode-hidden.block-bordered > .block-header {
  border-bottom: none; }

.block.block-mode-hidden > .block-content {
  display: none; }

.block.block-mode-loading {
  position: relative;
  overflow: hidden; }
  .block.block-mode-loading > .block-header,
  .block.block-mode-loading > .block-content,
  .block.block-mode-loading > .nav-tabs {
    opacity: .05; }
  .block.block-mode-loading::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 1;
    content: " "; }
  .block.block-mode-loading::after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #343a40;
    font-family: Simple-Line-Icons;
    font-size: 18px;
    text-align: center;
    z-index: 2;
    content: '\e09a';
    animation: fa-spin 1.75s infinite linear;
    border-radius: 25px;
    box-shadow: 0 0 15px 15px rgba(52, 58, 64, 0.05); }
  .block.block-mode-loading.block-mode-hidden::after {
    margin: -15px 0 0 -15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    box-shadow: 0 0 8px 8px rgba(52, 58, 64, 0.05); }
  .block.block-mode-loading.block-mode-loading-inverse::after {
    background-color: #343a40;
    color: #fff;
    box-shadow: 0 0 15px 15px rgba(255, 255, 255, 0.5); }
  .block.block-mode-loading.block-mode-loading-location::after {
    content: '\e06e'; }
  .block.block-mode-loading.block-mode-loading-energy::after {
    content: '\e020'; }
  .block.block-mode-loading.block-mode-loading-refresh::after {
    font-family: 'FontAwesome';
    content: '\f021'; }
  .block.block-mode-loading.block-mode-loading-sun::after {
    font-family: 'FontAwesome';
    content: '\f185'; }
  .block.block-mode-loading.block-mode-loading-repeat::after {
    font-family: 'FontAwesome';
    content: '\f01e'; }
  .lt-ie10 .block.block-mode-loading::after {
    content: 'Loading..' !important; }

.block.block-mode-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1034;
  margin-bottom: 0;
  overflow-y: auto;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch; }
  .block.block-mode-fullscreen.block-bordered {
    border: none; }
  .block.block-mode-fullscreen.block-rounded {
    border-radius: 0; }
  .block.block-mode-fullscreen.block-transparent {
    background-color: #fff; }

.block.block-mode-pinned {
  position: fixed;
  right: 10px;
  bottom: 0;
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 300px;
  z-index: 1031;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); }
  .block.block-mode-pinned > .block-content {
    max-height: 250px;
    overflow-y: auto; }

a.block {
  display: block;
  color: #575757;
  font-weight: normal;
  transition: all .12s ease-out; }
  a.block:hover {
    color: #575757;
    opacity: .65; }
  a.block:active {
    opacity: 1; }
  a.block.block-link-rotate:hover {
    transform: rotate(1deg);
    opacity: 1; }
  a.block.block-link-rotate:active {
    transform: rotate(0deg); }
  a.block.block-link-pop:hover {
    box-shadow: 0 2px 30px #d8dde5;
    transform: translateY(-3px);
    opacity: 1; }
  a.block.block-link-pop:active {
    box-shadow: 0 2px 10px #edeff3;
    transform: translateY(0); }
  a.block.block-link-shadow:hover {
    box-shadow: 0 0 36px #d8dde5;
    opacity: 1; }
  a.block.block-link-shadow:active {
    box-shadow: 0 0 10px #e4e7ed; }

.block.block-fx-rotate {
  transform: rotate(1deg);
  opacity: 1; }

.block.block-fx-pop {
  box-shadow: 0 2px 30px #d8dde5;
  transform: translateY(-3px);
  opacity: 1; }

.block.block-fx-shadow {
  box-shadow: 0 0 36px #d8dde5;
  opacity: 1; }

.block-options {
  flex: 0 0 auto;
  padding-left: 10px;
  min-height: 28px; }
  .block-options .block-options-item {
    padding: 6px 5px;
    line-height: 1.2; }
    .block.block-themed > .block-header .block-options .block-options-item {
      color: #fff; }
  .block-options .block-options-item,
  .block-options .dropdown,
  .block-options .dropup,
  .block-options .dropright,
  .block-options .dropleft {
    display: inline-block; }

.block-sticky-options {
  position: relative; }
  .block-sticky-options .block-options {
    position: absolute;
    top: 14px;
    right: 20px; }
    .block-sticky-options .block-options.block-options-left {
      right: auto;
      left: 20px;
      padding-right: 10px;
      padding-left: 0; }

.btn-block-option {
  display: inline-block;
  padding: 6px 5px;
  line-height: 1.2;
  color: #6c757d;
  background: none;
  border: none;
  cursor: pointer; }
  .btn-block-option .si {
    position: relative;
    top: 1px; }
  .btn-block-option:hover {
    text-decoration: none;
    color: #343a40; }
  a.btn-block-option:focus,
  .active > a.btn-block-option,
  .show > button.btn-block-option {
    text-decoration: none;
    color: #343a40; }
  .btn-block-option:active {
    color: #6c757d; }
  a.btn-block-option:focus,
  .active > a.btn-block-option,
  .show > button.btn-block-option {
    text-decoration: none;
    color: #343a40; }

.block.block-themed > .block-header > .block-options .btn-block-option,
.block.block-themed > .block-content > .block-options .btn-block-option {
  color: #fff;
  opacity: .7; }
  .block.block-themed > .block-header > .block-options .btn-block-option:hover,
  .block.block-themed > .block-content > .block-options .btn-block-option:hover {
    color: #fff;
    opacity: 1; }
  .block.block-themed > .block-header > .block-options .btn-block-option:active,
  .block.block-themed > .block-content > .block-options .btn-block-option:active {
    color: #fff;
    opacity: .6; }

.block.block-themed > .block-header > .block-options a.btn-block-option:focus,
.block.block-themed > .block-header > .block-options .active > a.btn-block-option,
.block.block-themed > .block-header > .block-options .show > button.btn-block-option,
.block.block-themed > .block-content > .block-options a.btn-block-option:focus,
.block.block-themed > .block-content > .block-options .active > a.btn-block-option,
.block.block-themed > .block-content > .block-options .show > button.btn-block-option {
  color: #fff;
  opacity: 1; }

#page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #3f9ce8;
  z-index: 999998;
  transition: transform .35s ease-out;
  transform: translateY(-100%);
  will-change: transform; }
  #page-loader.show {
    transform: translateY(0); }
  #page-loader::after, #page-loader::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    border-radius: 100%;
    content: '';
    z-index: 999999; }
  #page-loader::after {
    margin-top: -100px;
    margin-left: -100px;
    width: 200px;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 3px solid rgba(255, 255, 255, 0.75);
    -webkit-animation: page-loader-inner 1.5s infinite ease-out;
    animation: page-loader-inner 1.5s infinite ease-out; }
  #page-loader::before {
    margin-top: -30px;
    margin-left: -30px;
    width: 60px;
    height: 60px;
    background-color: white;
    -webkit-animation: page-loader-outer 1.5s infinite ease-in;
    animation: page-loader-outer 1.5s infinite ease-in; }
  .lt-ie10 #page-loader::after {
    text-align: center;
    content: 'Loading..';
    background-color: transparent; }
  .lt-ie10 #page-loader::before {
    display: none; }

@-webkit-keyframes page-loader-inner {
  0%, 40% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

@keyframes page-loader-inner {
  0%, 40% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

@-webkit-keyframes page-loader-outer {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  40%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; } }

@keyframes page-loader-outer {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  40%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; } }

.nav-main {
  margin: 0 -18px;
  padding: 0;
  list-style: none; }
  .nav-main .nav-main-heading {
    padding: 26px 18px 6px 18px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: .55; }
  .nav-main a {
    position: relative;
    display: block;
    padding: 9px 18px 9px 40px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(87, 87, 87, 0.9);
    text-transform: uppercase;
    letter-spacing: .5px; }
    .sidebar-inverse #sidebar .nav-main a {
      color: rgba(228, 231, 237, 0.75); }
    .nav-main a > i {
      position: absolute;
      top: 50%;
      right: auto;
      left: 18px;
      transform: translateX(0) translateY(-50%);
      font-size: 14px;
      color: rgba(52, 58, 64, 0.75); }
      .sidebar-inverse #sidebar .nav-main a > i {
        color: #e4e7ed; }
    .nav-main a:hover, .nav-main a:focus, .nav-main a.active {
      color: #000; }
      .sidebar-inverse #sidebar .nav-main a:hover, .sidebar-inverse #sidebar .nav-main a:focus, .sidebar-inverse #sidebar .nav-main a.active {
        color: #fff; }
      .nav-main a:hover > i, .nav-main a:focus > i, .nav-main a.active > i {
        color: #3f9ce8; }
        .sidebar-inverse #sidebar .nav-main a:hover > i, .sidebar-inverse #sidebar .nav-main a:focus > i, .sidebar-inverse #sidebar .nav-main a.active > i {
          color: #3f9ce8; }
    .nav-main a.nav-submenu {
      padding-right: 35px; }
    .nav-main a.nav-submenu::before, .nav-main a.nav-submenu::after {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -11px;
      width: 20px;
      height: 20px;
      display: block;
      line-height: 20px;
      text-align: center;
      font-family: 'FontAwesome';
      font-size: 14px;
      transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    .nav-main a.nav-submenu::before {
      content: '\f104';
      opacity: .4; }
    .nav-main a.nav-submenu::after {
      content: '\f103';
      opacity: 0;
      transform: rotate(90deg); }
  .nav-main ul {
    margin: 0;
    padding: 0 0 0 40px;
    height: 0;
    list-style: none;
    overflow: hidden; }
    .nav-main ul > li {
      opacity: 0;
      transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transform: translateY(-15px); }
    .nav-main ul .nav-main-heading {
      padding-left: 0;
      padding-right: 0; }
    .nav-main ul a {
      padding: 6px 8px 6px 0;
      text-transform: none;
      letter-spacing: normal;
      color: rgba(87, 87, 87, 0.6); }
      .nav-main ul a:hover, .nav-main ul a:focus {
        background-color: transparent; }
      .nav-main ul a > i {
        margin-right: 10px; }
    .nav-main ul ul {
      padding-left: 12px; }
  .nav-main li.open > a.nav-submenu {
    color: #000; }
    .sidebar-inverse #sidebar .nav-main li.open > a.nav-submenu {
      color: #fff; }
    .nav-main li.open > a.nav-submenu > i {
      color: #3f9ce8; }
      .sidebar-inverse #sidebar .nav-main li.open > a.nav-submenu > i {
        color: #3f9ce8; }
    .nav-main li.open > a.nav-submenu::before {
      opacity: 0;
      transform: rotate(-90deg); }
    .nav-main li.open > a.nav-submenu::after {
      opacity: .4;
      transform: rotate(0); }
  .nav-main li.open > ul {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f6f7f9; }
    .sidebar-inverse #sidebar .nav-main li.open > ul {
      background-color: #2d3238; }
    .nav-main li.open > ul > li {
      opacity: 1;
      transform: translateX(0); }

.nav-main-header {
  display: none; }
  @media (min-width: 992px) {
    .nav-main-header {
      margin: 0 -3px;
      padding: 0;
      display: inline-block;
      list-style: none;
      vertical-align: middle; }
      .nav-main-header li {
        position: relative;
        display: inline-block;
        margin: 0 3px; }
      .nav-main-header a {
        position: relative;
        display: inline-block;
        padding: 9px 14px;
        font-weight: 600;
        line-height: 16px;
        border-radius: 3px; }
        .nav-main-header a > i {
          margin-right: 8px; }
          .nav-main-header a > i.si {
            position: relative;
            top: 1px; }
        .nav-main-header a.nav-submenu {
          padding-right: 28px; }
        .nav-main-header a.nav-submenu::before {
          position: absolute;
          top: 50%;
          right: 6px;
          margin-top: -10px;
          width: 20px;
          height: 20px;
          display: block;
          text-align: center;
          line-height: 20px;
          font-family: 'FontAwesome'; }
        .nav-main-header a.nav-submenu::before {
          content: '\f107';
          opacity: .5; }
      .nav-main-header ul {
        position: absolute;
        top: 100%;
        left: 0;
        margin: 0;
        padding: 15px 0;
        display: none;
        z-index: 1032; }
        .nav-main-header ul li {
          display: block;
          margin: 0; }
        .nav-main-header ul a {
          display: block;
          border-radius: 0;
          min-width: 160px; }
          .nav-main-header ul a.nav-submenu::before {
            content: '\f105'; }
        .nav-main-header ul ul {
          top: -15px;
          left: 100%; }
      .nav-main-header li:hover > a.nav-submenu {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
      .nav-main-header li:hover > ul {
        display: block; }
      .nav-main-header > li:last-child ul {
        right: 0;
        left: auto; }
        .nav-main-header > li:last-child ul a.nav-submenu::before {
          content: '\f104'; }
        .nav-main-header > li:last-child ul ul {
          right: 100%;
          left: auto; }
      .nav-main-header a {
        color: #717070; }
      .nav-main-header ul a {
        color: #8f8f8f; }
      .nav-main-header a:hover,
      .nav-main-header a:focus,
      .nav-main-header a.active,
      .nav-main-header li.open > a.nav-submenu,
      .nav-main-header li:hover > a.nav-submenu {
        color: #000;
        background-color: #e4e7ed; }
      .nav-main-header ul,
      .nav-main-header > li:hover > a.nav-submenu {
        background-color: #dbdfe7; }
      .page-header-inverse #page-header .nav-main-header a,
      .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a {
        color: #c8d0da; }
      .page-header-inverse #page-header .nav-main-header ul a,
      .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header ul a {
        color: #adb8c8; }
      .page-header-inverse #page-header .nav-main-header a:hover,
      .page-header-inverse #page-header .nav-main-header a:focus,
      .page-header-inverse #page-header .nav-main-header a.active,
      .page-header-inverse #page-header .nav-main-header li.open > a.nav-submenu,
      .page-header-inverse #page-header .nav-main-header li:hover > a.nav-submenu,
      .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a:hover,
      .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a:focus,
      .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a.active,
      .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header li.open > a.nav-submenu,
      .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header li:hover > a.nav-submenu {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.25); }
      .page-header-inverse #page-header .nav-main-header ul,
      .page-header-inverse #page-header .nav-main-header > li:hover > a.nav-submenu,
      .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header ul,
      .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header > li:hover > a.nav-submenu {
        background-color: #343a40; }
      .page-header-inverse.page-header-glass #page-header .nav-main-header ul,
      .page-header-inverse.page-header-glass #page-header .nav-main-header > li:hover > a.nav-submenu {
        background-color: #292d32; }
      .nav-main-header .nav-main-heading {
        display: none; }
      .nav-main-header.nav-main-header-no-icons a > i {
        display: none; } }

.form-material {
  position: relative;
  padding-top: 26px; }
  .form-material > label {
    position: absolute;
    top: 6px;
    left: 0;
    margin-bottom: 0; }
  .form-material.floating > label {
    font-size: 1.14285714rem;
    font-weight: 400;
    cursor: text;
    z-index: 2;
    transition: all .2s ease-out;
    transform: translateY(25px); }
  .form-material.form-material-sm.floating > label {
    font-size: 1rem;
    transform: translateY(22px); }
  .form-material.form-material-lg.floating > label {
    font-size: 1.28571429rem;
    transform: translateY(27px); }
  .form-material > label,
  .form-material > .form-control:focus + label,
  .form-material.floating.open > label {
    font-size: 1rem;
    font-weight: 600;
    cursor: default;
    transform: translateY(0); }
  .form-material.floating > .form-control:-webkit-autofill + label {
    font-size: 1rem;
    font-weight: 600;
    cursor: default;
    transform: translateY(0); }
  .form-material.floating > .form-control[disabled] + label,
  .form-material.floating > .form-control[readonly] + label,
  fieldset[disabled] .form-material.floating > label {
    cursor: not-allowed; }
  .form-material > .form-control {
    padding-left: 0;
    padding-right: 0;
    border-color: transparent;
    border-radius: 0;
    background-color: transparent;
    box-shadow: 0 1px 0 #d4dae3;
    transition: box-shadow .3s ease-out; }
    .form-material > .form-control ~ .input-group-append {
      box-shadow: 0 1px 0 #d4dae3;
      transition: box-shadow .3s ease-out; }
    .form-material > .form-control:focus {
      background-color: transparent;
      border-color: transparent;
      box-shadow: 0 2px 0 #97a5ba; }
      .form-material > .form-control:focus + label {
        color: #575757; }
      .form-material > .form-control:focus ~ .input-group-append {
        box-shadow: 0 2px 0 #97a5ba; }
  .form-material > select.form-control {
    border-radius: 1px; }
  .form-material .form-control[disabled],
  .form-material .form-control[readonly],
  .form-material fieldset[disabled] .form-control {
    background-color: #fff;
    border-bottom: 1px dashed #ccc;
    box-shadow: none; }
  .form-material.input-group .input-group-text {
    border: none;
    background-color: transparent; }
  .form-material.form-material-primary > .form-control:focus {
    box-shadow: 0 2px #3f9ce8; }
    .form-material.form-material-primary > .form-control:focus + label {
      color: #3f9ce8; }
    .form-material.form-material-primary > .form-control:focus ~ .input-group-append {
      color: #3f9ce8;
      box-shadow: 0 2px #3f9ce8; }
  .form-material.form-material-success > .form-control:focus {
    box-shadow: 0 2px #9ccc65; }
    .form-material.form-material-success > .form-control:focus + label {
      color: #9ccc65; }
    .form-material.form-material-success > .form-control:focus ~ .input-group-append {
      color: #9ccc65;
      box-shadow: 0 2px #9ccc65; }
  .form-material.form-material-info > .form-control:focus {
    box-shadow: 0 2px #26c6da; }
    .form-material.form-material-info > .form-control:focus + label {
      color: #26c6da; }
    .form-material.form-material-info > .form-control:focus ~ .input-group-append {
      color: #26c6da;
      box-shadow: 0 2px #26c6da; }
  .form-material.form-material-warning > .form-control:focus {
    box-shadow: 0 2px #ffca28; }
    .form-material.form-material-warning > .form-control:focus + label {
      color: #ffca28; }
    .form-material.form-material-warning > .form-control:focus ~ .input-group-append {
      color: #ffca28;
      box-shadow: 0 2px #ffca28; }
  .form-material.form-material-danger > .form-control:focus {
    box-shadow: 0 2px #ef5350; }
    .form-material.form-material-danger > .form-control:focus + label {
      color: #ef5350; }
    .form-material.form-material-danger > .form-control:focus ~ .input-group-append {
      color: #ef5350;
      box-shadow: 0 2px #ef5350; }

.is-valid .form-material > .form-control {
  box-shadow: 0 1px 0 #9ccc65; }
  .is-valid .form-material > .form-control ~ .input-group-append {
    color: #9ccc65;
    box-shadow: 0 1px 0 #9ccc65; }
  .is-valid .form-material > .form-control:focus {
    box-shadow: 0 2px #9ccc65; }
    .is-valid .form-material > .form-control:focus + label {
      color: #9ccc65; }
    .is-valid .form-material > .form-control:focus ~ .input-group-append {
      color: #9ccc65;
      box-shadow: 0 2px #9ccc65; }

.is-valid .form-material ~ .invalid-feedback,
.is-valid .form-material ~ .invalid-tooltip {
  display: block; }

.is-valid .form-material label {
  color: #9ccc65; }

.is-invalid .form-material > .form-control {
  box-shadow: 0 1px 0 #ef5350; }
  .is-invalid .form-material > .form-control ~ .input-group-append {
    color: #ef5350;
    box-shadow: 0 1px 0 #ef5350; }
  .is-invalid .form-material > .form-control:focus {
    box-shadow: 0 2px #ef5350; }
    .is-invalid .form-material > .form-control:focus + label {
      color: #ef5350; }
    .is-invalid .form-material > .form-control:focus ~ .input-group-append {
      color: #ef5350;
      box-shadow: 0 2px #ef5350; }

.is-invalid .form-material ~ .invalid-feedback,
.is-invalid .form-material ~ .invalid-tooltip {
  display: block; }

.is-invalid .form-material label {
  color: #ef5350; }

.img-avatar {
  display: inline-block !important;
  width: 64px;
  height: 64px;
  border-radius: 50%; }
  .img-avatar.img-avatar16 {
    width: 16px;
    height: 16px; }
  .img-avatar.img-avatar20 {
    width: 20px;
    height: 20px; }
  .img-avatar.img-avatar32 {
    width: 32px;
    height: 32px; }
  .img-avatar.img-avatar48 {
    width: 48px;
    height: 48px; }
  .img-avatar.img-avatar96 {
    width: 96px;
    height: 96px; }
  .img-avatar.img-avatar128 {
    width: 128px;
    height: 128px; }
  .img-avatar-thumb {
    margin: 5px;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4); }
  .img-avatar.img-avatar-rounded {
    border-radius: 3px; }
  .img-avatar.img-avatar-square {
    border-radius: 0; }

.img-thumb {
  padding: 6px;
  background-color: #fff;
  border-radius: 3px; }

.img-link {
  display: inline-block;
  transition: transform .25s ease-out, opacity .25s ease-out; }
  .img-link:hover {
    transform: scale(1.04);
    opacity: .75; }
  .img-link:active {
    transform: scale(1);
    opacity: 1; }
  .img-link.img-link-simple:hover {
    transform: scale(1); }

.img-link-zoom-in {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.img-fluid.img-fluid-100,
.img-fluid-100 .img-fluid {
  width: 100%; }

.list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list > li {
    position: relative; }
  .list.list-simple > li,
  .list-li-push > li {
    margin-bottom: 20px; }
  .list.list-simple-mini > li,
  .list-li-push-sm > li {
    margin-bottom: 10px; }

.list-timeline {
  position: relative;
  padding-top: 20px; }
  .list-timeline > li {
    margin-bottom: 10px; }
  .list-timeline .list-timeline-time {
    margin: 0;
    padding: 10px 20px;
    min-height: 50px;
    text-align: right;
    color: #999;
    line-height: 30px;
    font-style: italic;
    background-color: #f6f7f9;
    border-radius: 2px; }
  .list-timeline .list-timeline-icon {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    color: #fff;
    text-align: center;
    border-radius: 10px; }
  .list-timeline .list-timeline-content {
    padding: 10px 10px 1px; }
    .list-timeline .list-timeline-content > p:first-child {
      margin-bottom: 0; }

@media (min-width: 768px) {
  .list-timeline-modern::before {
    position: absolute;
    top: 0;
    left: 119px;
    bottom: 0;
    display: block;
    width: 6px;
    content: '';
    background-color: #f6f7f9;
    z-index: 1; }
  .list-timeline-modern > li {
    min-height: 40px;
    z-index: 2; }
    .list-timeline-modern > li:last-child {
      margin-bottom: 0; }
  .list-timeline-modern .list-timeline-time {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding-right: 0;
    padding-left: 0;
    width: 90px;
    font-size: 0.92857143rem;
    line-height: normal;
    background-color: transparent; }
  .list-timeline-modern .list-timeline-icon {
    top: 3px;
    left: 105px;
    z-index: 2 !important; }
  .list-timeline-modern .list-timeline-content {
    padding-left: 155px; } }

.list-activity > li {
  margin-bottom: 10px;
  padding-bottom: 7px;
  padding-left: 35px;
  border-bottom: 1px solid #f0f2f5; }
  .list-activity > li > i:first-child {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    text-align: center; }

.list-activity > li:last-child {
  border-bottom: none; }

.list-events > li {
  margin-bottom: 7px;
  padding: 10px 30px 10px 7px;
  font-size: 0.92857143rem;
  font-weight: 600;
  background-color: #e3f4fc;
  border-left: 3px dotted rgba(0, 0, 0, 0.1);
  transition: transform .25s ease-out; }
  .list-events > li::before {
    position: absolute;
    top: 50%;
    right: auto;
    left: auto;
    transform: translateX(0) translateY(-50%);
    right: 10px;
    display: inline-block;
    font-family: 'FontAwesome';
    color: rgba(0, 0, 0, 0.3);
    content: '\f073'; }
  .list-events > li:hover {
    cursor: move;
    border-left-color: rgba(0, 0, 0, 0.3);
    transform: translateX(-5px); }

.item {
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 60px; }
  a.item:hover, a.item:focus {
    opacity: .6; }
  .item.item-circle {
    border-radius: 50%; }
  .item.item-rounded {
    border-radius: 3px; }
  .item.item-rounded-big {
    border-radius: 24px; }
    .item.item-rounded-big.item-2x {
      border-radius: 35px; }
    .item.item-rounded-big.item-3x {
      border-radius: 50px; }
  .item.item-2x {
    width: 100px;
    height: 100px;
    line-height: 100px; }
  .item.item-3x {
    width: 150px;
    height: 150px;
    line-height: 150px; }

.ribbon {
  position: relative;
  min-height: 52px; }
  .ribbon-box {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    color: #fff;
    font-weight: 600;
    z-index: 2; }
    .ribbon-box::before {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      content: ''; }
  .ribbon-bookmark .ribbon-box {
    padding-left: 10px; }
  .ribbon-bookmark .ribbon-box::before {
    top: 0;
    right: 100%;
    height: 32px;
    border: 16px solid;
    border-left-width: 8px;
    border-right-width: 0; }
  .ribbon-modern .ribbon-box {
    top: 10px;
    right: -8px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 16px; }
  .ribbon-modern .ribbon-box::before {
    display: none; }
  .ribbon-left .ribbon-box {
    right: auto;
    left: 0; }
  .ribbon-left.ribbon-bookmark .ribbon-box {
    padding-left: 12px;
    padding-right: 10px; }
  .ribbon-left.ribbon-bookmark .ribbon-box::before {
    right: auto;
    left: 100%;
    border-left-width: 0;
    border-right-width: 8px; }
  .ribbon-left.ribbon-modern .ribbon-box {
    left: -8px; }
  .ribbon-bottom .ribbon-box {
    top: auto;
    bottom: 10px; }
  .ribbon-primary .ribbon-box {
    background-color: #3f9ce8; }
  .ribbon-primary.ribbon-bookmark .ribbon-box::before {
    border-color: #3f9ce8;
    border-left-color: transparent; }
  .ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box::before {
    border-color: #3f9ce8;
    border-right-color: transparent; }
  .ribbon-primary.ribbon-modern .ribbon-box::before {
    border-color: #3f9ce8;
    border-left-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-primary.ribbon-modern.ribbon-bottom .ribbon-box::before {
    border-color: #3f9ce8;
    border-top-color: transparent;
    border-left-color: transparent; }
  .ribbon-primary.ribbon-modern.ribbon-left .ribbon-box::before {
    border-color: #3f9ce8;
    border-right-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-primary.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
    border-color: #3f9ce8;
    border-top-color: transparent;
    border-right-color: transparent; }
  .ribbon-success .ribbon-box {
    background-color: #9ccc65; }
  .ribbon-success.ribbon-bookmark .ribbon-box::before {
    border-color: #9ccc65;
    border-left-color: transparent; }
  .ribbon-success.ribbon-bookmark.ribbon-left .ribbon-box::before {
    border-color: #9ccc65;
    border-right-color: transparent; }
  .ribbon-success.ribbon-modern .ribbon-box::before {
    border-color: #9ccc65;
    border-left-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-success.ribbon-modern.ribbon-bottom .ribbon-box::before {
    border-color: #9ccc65;
    border-top-color: transparent;
    border-left-color: transparent; }
  .ribbon-success.ribbon-modern.ribbon-left .ribbon-box::before {
    border-color: #9ccc65;
    border-right-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-success.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
    border-color: #9ccc65;
    border-top-color: transparent;
    border-right-color: transparent; }
  .ribbon-info .ribbon-box {
    background-color: #26c6da; }
  .ribbon-info.ribbon-bookmark .ribbon-box::before {
    border-color: #26c6da;
    border-left-color: transparent; }
  .ribbon-info.ribbon-bookmark.ribbon-left .ribbon-box::before {
    border-color: #26c6da;
    border-right-color: transparent; }
  .ribbon-info.ribbon-modern .ribbon-box::before {
    border-color: #26c6da;
    border-left-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-info.ribbon-modern.ribbon-bottom .ribbon-box::before {
    border-color: #26c6da;
    border-top-color: transparent;
    border-left-color: transparent; }
  .ribbon-info.ribbon-modern.ribbon-left .ribbon-box::before {
    border-color: #26c6da;
    border-right-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-info.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
    border-color: #26c6da;
    border-top-color: transparent;
    border-right-color: transparent; }
  .ribbon-warning .ribbon-box {
    background-color: #ffca28; }
  .ribbon-warning.ribbon-bookmark .ribbon-box::before {
    border-color: #ffca28;
    border-left-color: transparent; }
  .ribbon-warning.ribbon-bookmark.ribbon-left .ribbon-box::before {
    border-color: #ffca28;
    border-right-color: transparent; }
  .ribbon-warning.ribbon-modern .ribbon-box::before {
    border-color: #ffca28;
    border-left-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-warning.ribbon-modern.ribbon-bottom .ribbon-box::before {
    border-color: #ffca28;
    border-top-color: transparent;
    border-left-color: transparent; }
  .ribbon-warning.ribbon-modern.ribbon-left .ribbon-box::before {
    border-color: #ffca28;
    border-right-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-warning.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
    border-color: #ffca28;
    border-top-color: transparent;
    border-right-color: transparent; }
  .ribbon-danger .ribbon-box {
    background-color: #ef5350; }
  .ribbon-danger.ribbon-bookmark .ribbon-box::before {
    border-color: #ef5350;
    border-left-color: transparent; }
  .ribbon-danger.ribbon-bookmark.ribbon-left .ribbon-box::before {
    border-color: #ef5350;
    border-right-color: transparent; }
  .ribbon-danger.ribbon-modern .ribbon-box::before {
    border-color: #ef5350;
    border-left-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-danger.ribbon-modern.ribbon-bottom .ribbon-box::before {
    border-color: #ef5350;
    border-top-color: transparent;
    border-left-color: transparent; }
  .ribbon-danger.ribbon-modern.ribbon-left .ribbon-box::before {
    border-color: #ef5350;
    border-right-color: transparent;
    border-bottom-color: transparent; }
  .ribbon-danger.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
    border-color: #ef5350;
    border-top-color: transparent;
    border-right-color: transparent; }
  .ribbon-crystal .ribbon-box {
    background-color: rgba(255, 255, 255, 0.35); }
  .ribbon-crystal.ribbon-bookmark .ribbon-box::before {
    border-color: rgba(255, 255, 255, 0.35);
    border-left-color: transparent; }
  .ribbon-crystal.ribbon-bookmark.ribbon-left .ribbon-box::before {
    border-color: rgba(255, 255, 255, 0.35);
    border-right-color: transparent; }

.options-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: block; }
  .options-container .options-item {
    transition: transform .35s ease-out;
    will-change: transform; }
  .options-container .options-overlay {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: 2;
    content: '';
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in;
    will-change: opacity, transform; }
    .options-container .options-overlay-content {
      position: absolute;
      top: 50%;
      right: auto;
      left: auto;
      transform: translateX(0) translateY(-50%);
      right: 0;
      left: 0;
      text-align: center; }
  .options-container:hover .options-overlay {
    opacity: 1;
    visibility: visible; }
  @media (max-width: 767.98px) {
    .options-container .options-overlay {
      display: none; }
    .options-container:hover .options-overlay {
      display: block; } }

.fx-item-zoom-in:hover .options-item {
  transform: scale(1.2, 1.2); }

.fx-item-rotate-r:hover .options-item {
  transform: scale(1.4) rotate(8deg); }

.fx-item-rotate-l:hover .options-item {
  transform: scale(1.4) rotate(-8deg); }

.fx-overlay-slide-top .options-overlay {
  transform: translateY(100%); }

.fx-overlay-slide-top:hover .options-overlay {
  transform: translateY(0); }

.fx-overlay-slide-right .options-overlay {
  transform: translateX(-100%); }

.fx-overlay-slide-right:hover .options-overlay {
  transform: translateX(0); }

.fx-overlay-slide-down .options-overlay {
  transform: translateY(-100%); }

.fx-overlay-slide-down:hover .options-overlay {
  transform: translateY(0); }

.fx-overlay-slide-left .options-overlay {
  transform: translateX(100%); }

.fx-overlay-slide-left:hover .options-overlay {
  transform: translateX(0); }

.fx-overlay-zoom-in .options-overlay {
  transform: scale(0, 0); }

.fx-overlay-zoom-in:hover .options-overlay {
  transform: scale(1, 1); }

.fx-overlay-zoom-out .options-overlay {
  transform: scale(2, 2); }

.fx-overlay-zoom-out:hover .options-overlay {
  transform: scale(1, 1); }

.scrumboard {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  padding: 12px 12px 1px; }
  @media (min-width: 768px) {
    .scrumboard {
      padding: 24px 24px 1px; } }
  .scrumboard .scrumboard-col {
    flex: 0 0 auto;
    width: 320px;
    margin-right: 12px; }
    @media (min-width: 768px) {
      .scrumboard .scrumboard-col {
        margin-right: 24px; } }
  .scrumboard .scrumboard-item {
    position: relative;
    min-height: 42px;
    padding: 10px 87px 10px 10px;
    margin-bottom: 15px;
    font-weight: 600;
    color: #a87e00;
    background-color: #fcf7e6;
    border-bottom: 1px solid rgba(168, 126, 0, 0.1);
    box-shadow: 0 5px 8px rgba(168, 126, 0, 0.05); }
  .scrumboard .scrumboard-item-options {
    position: absolute;
    top: 7px;
    right: 7px; }
  .scrumboard .scrumboard-item-handler {
    cursor: move; }
  .scrumboard .scrumboard-item-placeholder {
    min-height: 42px;
    border: 1px dashed #ffca28; }

@media print {
  #page-container,
  #main-container {
    padding: 0 !important; }
  #page-header,
  #page-footer,
  #page-loader,
  #sidebar,
  #side-overlay,
  .block-options {
    display: none !important; } }

.bg-primary-op {
  background-color: rgba(63, 156, 232, 0.75) !important; }

a.bg-primary-op:hover, a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
  background-color: rgba(26, 132, 218, 0.75) !important; }

.bg-primary-dark {
  background-color: #343a40 !important; }

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #1d2124 !important; }

.bg-primary-dark-op {
  background-color: rgba(52, 58, 64, 0.8) !important; }

a.bg-primary-dark-op:hover, a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
  background-color: rgba(29, 33, 36, 0.8) !important; }

.bg-primary-darker {
  background-color: #212529 !important; }

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #0a0c0d !important; }

.bg-primary-light {
  background-color: #83bff0 !important; }

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #56a8eb !important; }

.bg-primary-lighter {
  background-color: #c8e2f8 !important; }

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #9acbf3 !important; }

.bg-body {
  background-color: #f0f2f5 !important; }

a.bg-body:hover, a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
  background-color: #d1d7e1 !important; }

.bg-body-light {
  background-color: #f6f7f9 !important; }

a.bg-body-light:hover, a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
  background-color: #d8dde5 !important; }

.bg-body-dark {
  background-color: #e4e7ed !important; }

a.bg-body-dark:hover, a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
  background-color: #c5cdd8 !important; }

.bg-muted {
  background-color: #6c757d !important; }

a.bg-muted:hover, a.bg-muted:focus,
button.bg-muted:hover,
button.bg-muted:focus {
  background-color: #545b62 !important; }

.bg-success-light {
  background-color: #ebf5df !important; }

a.bg-success-light:hover, a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
  background-color: #d3e9b8 !important; }

.bg-warning-light {
  background-color: #fcf7e6 !important; }

a.bg-warning-light:hover, a.bg-warning-light:focus,
button.bg-warning-light:hover,
button.bg-warning-light:focus {
  background-color: #f7e8b8 !important; }

.bg-info-light {
  background-color: #e3f4fc !important; }

a.bg-info-light:hover, a.bg-info-light:focus,
button.bg-info-light:hover,
button.bg-info-light:focus {
  background-color: #b5e2f7 !important; }

.bg-danger-light {
  background-color: #fae9e8 !important; }

a.bg-danger-light:hover, a.bg-danger-light:focus,
button.bg-danger-light:hover,
button.bg-danger-light:focus {
  background-color: #f1c1be !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e5e5 !important; }

.bg-black {
  background-color: #000 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important; }

.bg-gray {
  background-color: #ced4da !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #b1bbc4 !important; }

.bg-gray-dark {
  background-color: #6c757d !important; }

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #545b62 !important; }

.bg-gray-darker {
  background-color: #343a40 !important; }

a.bg-gray-darker:hover, a.bg-gray-darker:focus,
button.bg-gray-darker:hover,
button.bg-gray-darker:focus {
  background-color: #1d2124 !important; }

.bg-gray-light {
  background-color: #e9ecef !important; }

a.bg-gray-light:hover, a.bg-gray-light:focus,
button.bg-gray-light:hover,
button.bg-gray-light:focus {
  background-color: #cbd3da !important; }

.bg-gray-lighter {
  background-color: #f8f9fa !important; }

a.bg-gray-lighter:hover, a.bg-gray-lighter:focus,
button.bg-gray-lighter:hover,
button.bg-gray-lighter:focus {
  background-color: #dae0e5 !important; }

.bg-gd-primary {
  background: #1776c3;
  background: linear-gradient(135deg, #1776c3 0%, #56a8eb 100%) !important; }

.bg-gd-dusk {
  background: #d262e3;
  background: linear-gradient(135deg, #d262e3 0%, #3f9ce8 100%) !important; }

.bg-gd-cherry {
  background: #ea6153;
  background: linear-gradient(135deg, #ea6153 0%, #d62c1a 100%) !important; }

.bg-gd-aqua {
  background: #2facb2;
  background: linear-gradient(135deg, #2facb2 0%, #70b29c 100%) !important; }

.bg-gd-emerald {
  background: #6eab4d;
  background: linear-gradient(135deg, #6eab4d 0%, #2facb2 100%) !important; }

.bg-gd-sea {
  background: #1f8ce4;
  background: linear-gradient(135deg, #1f8ce4 0%, #26c6da 100%) !important; }

.bg-gd-leaf {
  background: #ffca28;
  background: linear-gradient(135deg, #ffca28 0%, #9ccc65 100%) !important; }

.bg-gd-lake {
  background: #26c6da;
  background: linear-gradient(135deg, #26c6da 0%, #9ccc65 100%) !important; }

.bg-gd-sun {
  background: #ef5350;
  background: linear-gradient(135deg, #ef5350 0%, #ffca28 100%) !important; }

.bg-black-op {
  background-color: rgba(0, 0, 0, 0.5) !important; }
  .bg-black-op-5 {
    background-color: rgba(0, 0, 0, 0.05) !important; }
  .bg-black-op-10 {
    background-color: rgba(0, 0, 0, 0.1) !important; }
  .bg-black-op-25 {
    background-color: rgba(0, 0, 0, 0.25) !important; }
  .bg-black-op-75 {
    background-color: rgba(0, 0, 0, 0.75) !important; }
  .bg-black-op-90 {
    background-color: rgba(0, 0, 0, 0.9) !important; }
  .bg-black-op-95 {
    background-color: rgba(0, 0, 0, 0.95) !important; }

.bg-white-op {
  background-color: rgba(255, 255, 255, 0.5) !important; }
  .bg-white-op-5 {
    background-color: rgba(255, 255, 255, 0.05) !important; }
  .bg-white-op-10 {
    background-color: rgba(255, 255, 255, 0.1) !important; }
  .bg-white-op-25 {
    background-color: rgba(255, 255, 255, 0.25) !important; }
  .bg-white-op-75 {
    background-color: rgba(255, 255, 255, 0.75) !important; }
  .bg-white-op-90 {
    background-color: rgba(255, 255, 255, 0.9) !important; }
  .bg-white-op-95 {
    background-color: rgba(255, 255, 255, 0.95) !important; }

.bg-image {
  background-color: #f8f9fa;
  background-position: 0% 50%;
  background-size: cover; }
  .bg-image-top {
    background-position-y: 0%; }
  .bg-image-bottom {
    background-position-y: 100%; }
  @media (min-width: 1200px) {
    .bg-image-fixed {
      background-attachment: fixed; } }

.bg-pattern {
  background-repeat: repeat; }

.bg-video {
  width: 100%;
  transform: translateZ(0); }

.border {
  border: 1px solid #e4e7ed !important; }
  .border-t {
    border-top: 1px solid #e4e7ed !important; }
  .border-r {
    border-right: 1px solid #e4e7ed !important; }
  .border-b {
    border-bottom: 1px solid #e4e7ed !important; }
  .border-l {
    border-left: 1px solid #e4e7ed !important; }

.border-light {
  border: 1px solid #f6f7f9 !important; }
  .border-light-t {
    border-top: 1px solid #f6f7f9 !important; }
  .border-light-r {
    border-right: 1px solid #f6f7f9 !important; }
  .border-light-b {
    border-bottom: 1px solid #f6f7f9 !important; }
  .border-light-l {
    border-left: 1px solid #f6f7f9 !important; }

.border-white-op {
  border: 1px solid rgba(255, 255, 255, 0.1) !important; }
  .border-white-op-t {
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important; }
  .border-white-op-r {
    border-right: 1px solid rgba(255, 255, 255, 0.1) !important; }
  .border-white-op-b {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important; }
  .border-white-op-l {
    border-left: 1px solid rgba(255, 255, 255, 0.1) !important; }

.border-black-op {
  border: 1px solid rgba(0, 0, 0, 0.1) !important; }
  .border-black-op-t {
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important; }
  .border-black-op-r {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important; }
  .border-black-op-b {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important; }
  .border-black-op-l {
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important; }

.border-2x {
  border-width: 2px !important; }

.border-3x {
  border-width: 3px !important; }

.overflow-hidden {
  overflow: hidden; }

.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.opacity-0 {
  opacity: 0; }

.opacity-1 {
  opacity: 1; }

.click-ripple {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  transform: scale(0); }
  .click-ripple.animate {
    animation: click-ripple .9s ease-out; }

@keyframes click-ripple {
  100% {
    opacity: 0;
    transform: scale(3); } }

.visible-lt-ie10 {
  display: none; }

.lt-ie10 .hidden-lt-ie10 {
  display: none !important; }

.lt-ie10 .visible-lt-ie10 {
  display: block; }

.lt-ie10 .visible-lt-ie10-ib {
  display: inline-block; }

.lt-ie10.lt-ie10-msg body {
  background-color: #fff; }

.lt-ie10.lt-ie10-msg::before {
  position: fixed;
  top: 100px;
  right: 0;
  left: 0;
  display: block;
  padding: 30px 20px;
  text-align: center;
  font-size: 1.14285714rem;
  font-weight: 600;
  background: #ffca28;
  color: #755800;
  content: 'Internet Explorer 9 and older versions are not supported. Please update your browser.';
  z-index: 9999999; }

.lt-ie10.lt-ie10-msg #page-container {
  display: none; }

.min-width-20 {
  min-width: 20px !important; }

.min-width-30 {
  min-width: 30px !important; }

.min-width-50 {
  min-width: 50px !important; }

.min-width-75 {
  min-width: 75px !important; }

.min-width-100 {
  min-width: 100px !important; }

.min-width-125 {
  min-width: 125px !important; }

.min-width-150 {
  min-width: 150px !important; }

.min-width-175 {
  min-width: 175px !important; }

.min-width-200 {
  min-width: 200px !important; }

.min-width-300 {
  min-width: 300px !important; }

.min-width-320 {
  min-width: 320px !important; }

.min-height-20 {
  min-height: 20px !important; }

.min-height-30 {
  min-height: 30px !important; }

.min-height-50 {
  min-height: 50px !important; }

.min-height-75 {
  min-height: 75px !important; }

.min-height-100 {
  min-height: 100px !important; }

.min-height-125 {
  min-height: 125px !important; }

.min-height-150 {
  min-height: 150px !important; }

.min-height-175 {
  min-height: 175px !important; }

.min-height-200 {
  min-height: 200px !important; }

.min-height-300 {
  min-height: 300px !important; }

.min-height-400 {
  min-height: 400px !important; }

.min-height-500 {
  min-height: 500px !important; }

.m-5 {
  margin: 5px 5px !important; }

.mt-5,
.my-5 {
  margin-top: 5px !important; }

.mr-5,
.mx-5 {
  margin-right: 5px !important; }

.mb-5,
.my-5 {
  margin-bottom: 5px !important; }

.ml-5,
.mx-5 {
  margin-left: 5px !important; }

.m-10 {
  margin: 10px 10px !important; }

.mt-10,
.my-10 {
  margin-top: 10px !important; }

.mr-10,
.mx-10 {
  margin-right: 10px !important; }

.mb-10,
.my-10 {
  margin-bottom: 10px !important; }

.ml-10,
.mx-10 {
  margin-left: 10px !important; }

.m-15 {
  margin: 15px 15px !important; }

.mt-15,
.my-15 {
  margin-top: 15px !important; }

.mr-15,
.mx-15 {
  margin-right: 15px !important; }

.mb-15,
.my-15 {
  margin-bottom: 15px !important; }

.ml-15,
.mx-15 {
  margin-left: 15px !important; }

.m-20 {
  margin: 20px 20px !important; }

.mt-20,
.my-20 {
  margin-top: 20px !important; }

.mr-20,
.mx-20 {
  margin-right: 20px !important; }

.mb-20,
.my-20 {
  margin-bottom: 20px !important; }

.ml-20,
.mx-20 {
  margin-left: 20px !important; }

.m-30 {
  margin: 30px 30px !important; }

.mt-30,
.my-30 {
  margin-top: 30px !important; }

.mr-30,
.mx-30 {
  margin-right: 30px !important; }

.mb-30,
.my-30 {
  margin-bottom: 30px !important; }

.ml-30,
.mx-30 {
  margin-left: 30px !important; }

.m-50 {
  margin: 50px 50px !important; }

.mt-50,
.my-50 {
  margin-top: 50px !important; }

.mr-50,
.mx-50 {
  margin-right: 50px !important; }

.mb-50,
.my-50 {
  margin-bottom: 50px !important; }

.ml-50,
.mx-50 {
  margin-left: 50px !important; }

.m-100 {
  margin: 100px 100px !important; }

.mt-100,
.my-100 {
  margin-top: 100px !important; }

.mr-100,
.mx-100 {
  margin-right: 100px !important; }

.mb-100,
.my-100 {
  margin-bottom: 100px !important; }

.ml-100,
.mx-100 {
  margin-left: 100px !important; }

.m-150 {
  margin: 150px 150px !important; }

.mt-150,
.my-150 {
  margin-top: 150px !important; }

.mr-150,
.mx-150 {
  margin-right: 150px !important; }

.mb-150,
.my-150 {
  margin-bottom: 150px !important; }

.ml-150,
.mx-150 {
  margin-left: 150px !important; }

.m-200 {
  margin: 200px 200px !important; }

.mt-200,
.my-200 {
  margin-top: 200px !important; }

.mr-200,
.mx-200 {
  margin-right: 200px !important; }

.mb-200,
.my-200 {
  margin-bottom: 200px !important; }

.ml-200,
.mx-200 {
  margin-left: 200px !important; }

.m-300 {
  margin: 300px 300px !important; }

.mt-300,
.my-300 {
  margin-top: 300px !important; }

.mr-300,
.mx-300 {
  margin-right: 300px !important; }

.mb-300,
.my-300 {
  margin-bottom: 300px !important; }

.ml-300,
.mx-300 {
  margin-left: 300px !important; }

.p-5 {
  padding: 5px 5px !important; }

.pt-5,
.py-5 {
  padding-top: 5px !important; }

.pr-5,
.px-5 {
  padding-right: 5px !important; }

.pb-5,
.py-5 {
  padding-bottom: 5px !important; }

.pl-5,
.px-5 {
  padding-left: 5px !important; }

.p-10 {
  padding: 10px 10px !important; }

.pt-10,
.py-10 {
  padding-top: 10px !important; }

.pr-10,
.px-10 {
  padding-right: 10px !important; }

.pb-10,
.py-10 {
  padding-bottom: 10px !important; }

.pl-10,
.px-10 {
  padding-left: 10px !important; }

.p-15 {
  padding: 15px 15px !important; }

.pt-15,
.py-15 {
  padding-top: 15px !important; }

.pr-15,
.px-15 {
  padding-right: 15px !important; }

.pb-15,
.py-15 {
  padding-bottom: 15px !important; }

.pl-15,
.px-15 {
  padding-left: 15px !important; }

.p-20 {
  padding: 20px 20px !important; }

.pt-20,
.py-20 {
  padding-top: 20px !important; }

.pr-20,
.px-20 {
  padding-right: 20px !important; }

.pb-20,
.py-20 {
  padding-bottom: 20px !important; }

.pl-20,
.px-20 {
  padding-left: 20px !important; }

.p-30 {
  padding: 30px 30px !important; }

.pt-30,
.py-30 {
  padding-top: 30px !important; }

.pr-30,
.px-30 {
  padding-right: 30px !important; }

.pb-30,
.py-30 {
  padding-bottom: 30px !important; }

.pl-30,
.px-30 {
  padding-left: 30px !important; }

.p-50 {
  padding: 50px 50px !important; }

.pt-50,
.py-50 {
  padding-top: 50px !important; }

.pr-50,
.px-50 {
  padding-right: 50px !important; }

.pb-50,
.py-50 {
  padding-bottom: 50px !important; }

.pl-50,
.px-50 {
  padding-left: 50px !important; }

.p-100 {
  padding: 100px 100px !important; }

.pt-100,
.py-100 {
  padding-top: 100px !important; }

.pr-100,
.px-100 {
  padding-right: 100px !important; }

.pb-100,
.py-100 {
  padding-bottom: 100px !important; }

.pl-100,
.px-100 {
  padding-left: 100px !important; }

.p-150 {
  padding: 150px 150px !important; }

.pt-150,
.py-150 {
  padding-top: 150px !important; }

.pr-150,
.px-150 {
  padding-right: 150px !important; }

.pb-150,
.py-150 {
  padding-bottom: 150px !important; }

.pl-150,
.px-150 {
  padding-left: 150px !important; }

.p-200 {
  padding: 200px 200px !important; }

.pt-200,
.py-200 {
  padding-top: 200px !important; }

.pr-200,
.px-200 {
  padding-right: 200px !important; }

.pb-200,
.py-200 {
  padding-bottom: 200px !important; }

.pl-200,
.px-200 {
  padding-left: 200px !important; }

.p-300 {
  padding: 300px 300px !important; }

.pt-300,
.py-300 {
  padding-top: 300px !important; }

.pr-300,
.px-300 {
  padding-right: 300px !important; }

.pb-300,
.py-300 {
  padding-bottom: 300px !important; }

.pl-300,
.px-300 {
  padding-left: 300px !important; }

.push,
.items-push > div {
  margin-bottom: 24px; }

.font-w300 {
  font-weight: 300 !important; }

.font-w400 {
  font-weight: 400 !important; }

.font-w600 {
  font-weight: 600 !important; }

.font-w700 {
  font-weight: 700 !important; }

.font-size-default {
  font-size: 1rem !important; }

.font-size-xl {
  font-size: 1.42857143rem !important; }

.font-size-lg {
  font-size: 1.28571429rem !important; }

.font-size-md {
  font-size: 1.14285714rem !important; }

.font-size-sm {
  font-size: 0.92857143rem !important; }

.font-size-xs {
  font-size: 0.85714286rem !important; }

.line-height-base {
  line-height: 1.5 !important; }

.text-wrap-break-word {
  word-wrap: break-word; }

.text-uppercase {
  letter-spacing: .0625em; }

.text-primary-dark {
  color: #343a40 !important; }

a.text-primary-dark.link-effect::before {
  background-color: #343a40; }

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #060708 !important; }

.text-primary-darker {
  color: #212529 !important; }

a.text-primary-darker.link-effect::before {
  background-color: #212529; }

a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: black !important; }

.text-primary-light {
  color: #83bff0 !important; }

a.text-primary-light.link-effect::before {
  background-color: #83bff0; }

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #2890e5 !important; }

.text-primary-lighter {
  color: #c8e2f8 !important; }

a.text-primary-lighter.link-effect::before {
  background-color: #c8e2f8; }

a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #6db3ed !important; }

.text-body-bg {
  color: #f0f2f5 !important; }

a.text-body-bg.link-effect::before {
  background-color: #f0f2f5; }

a.text-body-bg:hover, a.text-body-bg:focus {
  color: #b3bdcc !important; }

.text-body-bg-dark {
  color: #e4e7ed !important; }

a.text-body-bg-dark.link-effect::before {
  background-color: #e4e7ed; }

a.text-body-bg-dark:hover, a.text-body-bg-dark:focus {
  color: #a7b2c4 !important; }

.text-body-bg-light {
  color: #f6f7f9 !important; }

a.text-body-bg-light.link-effect::before {
  background-color: #f6f7f9; }

a.text-body-bg-light:hover, a.text-body-bg-light:focus {
  color: #b9c2d0 !important; }

.text-body-color {
  color: #575757 !important; }

a.text-body-color.link-effect::before {
  background-color: #575757; }

a.text-body-color:hover, a.text-body-color:focus {
  color: #242424 !important; }

.text-body-color-dark {
  color: #171717 !important; }

a.text-body-color-dark.link-effect::before {
  background-color: #171717; }

a.text-body-color-dark:hover, a.text-body-color-dark:focus {
  color: black !important; }

.text-body-color-light {
  color: #e4e7ed !important; }

a.text-body-color-light.link-effect::before {
  background-color: #e4e7ed; }

a.text-body-color-light:hover, a.text-body-color-light:focus {
  color: #a7b2c4 !important; }

.text-muted {
  color: #6c757d !important; }

a.text-muted.link-effect::before {
  background-color: #6c757d; }

a.text-muted:hover, a.text-muted:focus {
  color: #3d4246 !important; }

.text-success-light {
  color: #ebf5df !important; }

a.text-success-light.link-effect::before {
  background-color: #ebf5df; }

a.text-success-light:hover, a.text-success-light:focus {
  color: #badd91 !important; }

.text-warning-light {
  color: #fcf7e6 !important; }

a.text-warning-light.link-effect::before {
  background-color: #fcf7e6; }

a.text-warning-light:hover, a.text-warning-light:focus {
  color: #f1da8b !important; }

.text-info-light {
  color: #e3f4fc !important; }

a.text-info-light.link-effect::before {
  background-color: #e3f4fc; }

a.text-info-light:hover, a.text-info-light:focus {
  color: #87d0f2 !important; }

.text-danger-light {
  color: #fae9e8 !important; }

a.text-danger-light.link-effect::before {
  background-color: #fae9e8; }

a.text-danger-light:hover, a.text-danger-light:focus {
  color: #e89994 !important; }

.text-white {
  color: #fff !important; }

a.text-white.link-effect::before {
  background-color: #fff; }

a.text-white:hover, a.text-white:focus {
  color: #cccccc !important; }

.text-white-op {
  color: rgba(255, 255, 255, 0.8) !important; }

a.text-white-op.link-effect::before {
  background-color: rgba(255, 255, 255, 0.8); }

a.text-white-op:hover, a.text-white-op:focus {
  color: rgba(204, 204, 204, 0.8) !important; }

.text-black {
  color: #000 !important; }

a.text-black.link-effect::before {
  background-color: #000; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.text-black-op {
  color: rgba(0, 0, 0, 0.5) !important; }

a.text-black-op.link-effect::before {
  background-color: rgba(0, 0, 0, 0.5); }

a.text-black-op:hover, a.text-black-op:focus {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-gray {
  color: #ced4da !important; }

a.text-gray.link-effect::before {
  background-color: #ced4da; }

a.text-gray:hover, a.text-gray:focus {
  color: #94a1ae !important; }

.text-gray-dark {
  color: #6c757d !important; }

a.text-gray-dark.link-effect::before {
  background-color: #6c757d; }

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #3d4246 !important; }

.text-gray-darker {
  color: #343a40 !important; }

a.text-gray-darker.link-effect::before {
  background-color: #343a40; }

a.text-gray-darker:hover, a.text-gray-darker:focus {
  color: #060708 !important; }

.text-gray-light {
  color: #e9ecef !important; }

a.text-gray-light.link-effect::before {
  background-color: #e9ecef; }

a.text-gray-light:hover, a.text-gray-light:focus {
  color: #aeb9c4 !important; }

.text-gray-lighter {
  color: #f8f9fa !important; }

a.text-gray-lighter.link-effect::before {
  background-color: #f8f9fa; }

a.text-gray-lighter:hover, a.text-gray-lighter:focus {
  color: #bdc6d0 !important; }

.text-dual-primary-dark {
  color: #343a40 !important; }

a.text-dual-primary-dark.link-effect::before {
  background-color: #343a40; }

a.text-dual-primary-dark:hover, a.text-dual-primary-dark:focus {
  color: #060708 !important; }

.page-header-inverse #page-header .text-dual-primary-dark,
.sidebar-inverse #sidebar .text-dual-primary-dark {
  color: #e4e7ed !important; }

.page-header-inverse #page-header a.text-dual-primary-dark.link-effect::before,
.sidebar-inverse #sidebar a.text-dual-primary-dark.link-effect::before {
  background-color: #e4e7ed; }

.page-header-inverse #page-header a.text-dual-primary-dark:hover, .page-header-inverse #page-header a.text-dual-primary-dark:focus,
.sidebar-inverse #sidebar a.text-dual-primary-dark:hover,
.sidebar-inverse #sidebar a.text-dual-primary-dark:focus {
  color: #a7b2c4 !important; }

.align-parent {
  position: relative; }

.align-v {
  position: absolute;
  top: 50%;
  right: auto;
  left: auto;
  transform: translateX(0) translateY(-50%); }

.align-v-r {
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  transform: translateX(0) translateY(-50%); }

.align-v-l {
  position: absolute;
  top: 50%;
  right: auto;
  left: 0;
  transform: translateX(0) translateY(-50%); }

.align-v-r {
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  transform: translateX(0) translateY(-50%); }

.align-v-h {
  position: absolute;
  top: 50%;
  right: auto;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

.text-default {
  color: #3f9ce8 !important; }

a.text-default.link-effect::before {
  background-color: #3f9ce8; }

a.text-default:hover, a.text-default:focus {
  color: #1568ac !important; }

.text-default-dark {
  color: #343a40 !important; }

a.text-default-dark.link-effect::before {
  background-color: #343a40; }

a.text-default-dark:hover, a.text-default-dark:focus {
  color: #060708 !important; }

.text-default-darker {
  color: #212529 !important; }

a.text-default-darker.link-effect::before {
  background-color: #212529; }

a.text-default-darker:hover, a.text-default-darker:focus {
  color: black !important; }

.text-default-light {
  color: #83bff0 !important; }

a.text-default-light.link-effect::before {
  background-color: #83bff0; }

a.text-default-light:hover, a.text-default-light:focus {
  color: #2890e5 !important; }

.text-default-lighter {
  color: #c8e2f8 !important; }

a.text-default-lighter.link-effect::before {
  background-color: #c8e2f8; }

a.text-default-lighter:hover, a.text-default-lighter:focus {
  color: #6db3ed !important; }

.bg-default {
  background-color: #3f9ce8 !important; }

a.bg-default:hover, a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #1a84da !important; }

.bg-default-op {
  background-color: rgba(63, 156, 232, 0.75) !important; }

a.bg-default-op:hover, a.bg-default-op:focus,
button.bg-default-op:hover,
button.bg-default-op:focus {
  background-color: rgba(26, 132, 218, 0.75) !important; }

.bg-default-dark {
  background-color: #343a40 !important; }

a.bg-default-dark:hover, a.bg-default-dark:focus,
button.bg-default-dark:hover,
button.bg-default-dark:focus {
  background-color: #1d2124 !important; }

.bg-default-dark-op {
  background-color: rgba(52, 58, 64, 0.83) !important; }

a.bg-default-dark-op:hover, a.bg-default-dark-op:focus,
button.bg-default-dark-op:hover,
button.bg-default-dark-op:focus {
  background-color: rgba(29, 33, 36, 0.83) !important; }

.bg-default-darker {
  background-color: #212529 !important; }

a.bg-default-darker:hover, a.bg-default-darker:focus,
button.bg-default-darker:hover,
button.bg-default-darker:focus {
  background-color: #0a0c0d !important; }

.bg-default-light {
  background-color: #83bff0 !important; }

a.bg-default-light:hover, a.bg-default-light:focus,
button.bg-default-light:hover,
button.bg-default-light:focus {
  background-color: #56a8eb !important; }

.bg-default-lighter {
  background-color: #c8e2f8 !important; }

a.bg-default-lighter:hover, a.bg-default-lighter:focus,
button.bg-default-lighter:hover,
button.bg-default-lighter:focus {
  background-color: #9acbf3 !important; }

.bg-gd-default {
  background: #1776c3;
  background: linear-gradient(135deg, #1776c3 0%, #56a8eb 100%) !important; }

.text-elegance {
  color: #d262e3 !important; }

a.text-elegance.link-effect::before {
  background-color: #d262e3; }

a.text-elegance:hover, a.text-elegance:focus {
  color: #a922bd !important; }

.text-elegance-dark {
  color: #403537 !important; }

a.text-elegance-dark.link-effect::before {
  background-color: #403537; }

a.text-elegance-dark:hover, a.text-elegance-dark:focus {
  color: #080707 !important; }

.text-elegance-darker {
  color: #332a2c !important; }

a.text-elegance-darker.link-effect::before {
  background-color: #332a2c; }

a.text-elegance-darker:hover, a.text-elegance-darker:focus {
  color: black !important; }

.text-elegance-light {
  color: #e196ec !important; }

a.text-elegance-light.link-effect::before {
  background-color: #e196ec; }

a.text-elegance-light:hover, a.text-elegance-light:focus {
  color: #c83fdd !important; }

.text-elegance-lighter {
  color: #edc1f4 !important; }

a.text-elegance-lighter.link-effect::before {
  background-color: #edc1f4; }

a.text-elegance-lighter:hover, a.text-elegance-lighter:focus {
  color: #d46be5 !important; }

.bg-elegance {
  background-color: #d262e3 !important; }

a.bg-elegance:hover, a.bg-elegance:focus,
button.bg-elegance:hover,
button.bg-elegance:focus {
  background-color: #c637db !important; }

.bg-elegance-op {
  background-color: rgba(210, 98, 227, 0.75) !important; }

a.bg-elegance-op:hover, a.bg-elegance-op:focus,
button.bg-elegance-op:hover,
button.bg-elegance-op:focus {
  background-color: rgba(198, 55, 219, 0.75) !important; }

.bg-elegance-dark {
  background-color: #403537 !important; }

a.bg-elegance-dark:hover, a.bg-elegance-dark:focus,
button.bg-elegance-dark:hover,
button.bg-elegance-dark:focus {
  background-color: #241e1f !important; }

.bg-elegance-dark-op {
  background-color: rgba(64, 53, 55, 0.83) !important; }

a.bg-elegance-dark-op:hover, a.bg-elegance-dark-op:focus,
button.bg-elegance-dark-op:hover,
button.bg-elegance-dark-op:focus {
  background-color: rgba(36, 30, 31, 0.83) !important; }

.bg-elegance-darker {
  background-color: #332a2c !important; }

a.bg-elegance-darker:hover, a.bg-elegance-darker:focus,
button.bg-elegance-darker:hover,
button.bg-elegance-darker:focus {
  background-color: #171314 !important; }

.bg-elegance-light {
  background-color: #e196ec !important; }

a.bg-elegance-light:hover, a.bg-elegance-light:focus,
button.bg-elegance-light:hover,
button.bg-elegance-light:focus {
  background-color: #d46be5 !important; }

.bg-elegance-lighter {
  background-color: #edc1f4 !important; }

a.bg-elegance-lighter:hover, a.bg-elegance-lighter:focus,
button.bg-elegance-lighter:hover,
button.bg-elegance-lighter:focus {
  background-color: #e196ec !important; }

.bg-gd-elegance {
  background: #bc26d3;
  background: linear-gradient(135deg, #bc26d3 0%, #d878e7 100%) !important; }

.text-pulse {
  color: #e74c3c !important; }

a.text-pulse.link-effect::before {
  background-color: #e74c3c; }

a.text-pulse:hover, a.text-pulse:focus {
  color: #a82315 !important; }

.text-pulse-dark {
  color: #383838 !important; }

a.text-pulse-dark.link-effect::before {
  background-color: #383838; }

a.text-pulse-dark:hover, a.text-pulse-dark:focus {
  color: #050505 !important; }

.text-pulse-darker {
  color: #232323 !important; }

a.text-pulse-darker.link-effect::before {
  background-color: #232323; }

a.text-pulse-darker:hover, a.text-pulse-darker:focus {
  color: black !important; }

.text-pulse-light {
  color: #eb6d60 !important; }

a.text-pulse-light.link-effect::before {
  background-color: #eb6d60; }

a.text-pulse-light:hover, a.text-pulse-light:focus {
  color: #cd2a19 !important; }

.text-pulse-lighter {
  color: #f3a8a0 !important; }

a.text-pulse-lighter.link-effect::before {
  background-color: #f3a8a0; }

a.text-pulse-lighter:hover, a.text-pulse-lighter:focus {
  color: #e85445 !important; }

.bg-pulse {
  background-color: #e74c3c !important; }

a.bg-pulse:hover, a.bg-pulse:focus,
button.bg-pulse:hover,
button.bg-pulse:focus {
  background-color: #d62c1a !important; }

.bg-pulse-op {
  background-color: rgba(231, 76, 60, 0.75) !important; }

a.bg-pulse-op:hover, a.bg-pulse-op:focus,
button.bg-pulse-op:hover,
button.bg-pulse-op:focus {
  background-color: rgba(214, 44, 26, 0.75) !important; }

.bg-pulse-dark {
  background-color: #383838 !important; }

a.bg-pulse-dark:hover, a.bg-pulse-dark:focus,
button.bg-pulse-dark:hover,
button.bg-pulse-dark:focus {
  background-color: #1f1e1e !important; }

.bg-pulse-dark-op {
  background-color: rgba(56, 56, 56, 0.83) !important; }

a.bg-pulse-dark-op:hover, a.bg-pulse-dark-op:focus,
button.bg-pulse-dark-op:hover,
button.bg-pulse-dark-op:focus {
  background-color: rgba(31, 30, 30, 0.83) !important; }

.bg-pulse-darker {
  background-color: #232323 !important; }

a.bg-pulse-darker:hover, a.bg-pulse-darker:focus,
button.bg-pulse-darker:hover,
button.bg-pulse-darker:focus {
  background-color: #0a0a0a !important; }

.bg-pulse-light {
  background-color: #eb6d60 !important; }

a.bg-pulse-light:hover, a.bg-pulse-light:focus,
button.bg-pulse-light:hover,
button.bg-pulse-light:focus {
  background-color: #e64433 !important; }

.bg-pulse-lighter {
  background-color: #f3a8a0 !important; }

a.bg-pulse-lighter:hover, a.bg-pulse-lighter:focus,
button.bg-pulse-lighter:hover,
button.bg-pulse-lighter:focus {
  background-color: #ee7e72 !important; }

.bg-gd-pulse {
  background: #bf2718;
  background: linear-gradient(135deg, #bf2718 0%, #ea6153 100%) !important; }

.text-flat {
  color: #70b29c !important; }

a.text-flat.link-effect::before {
  background-color: #70b29c; }

a.text-flat:hover, a.text-flat:focus {
  color: #427a67 !important; }

.text-flat-dark {
  color: #113f4c !important; }

a.text-flat-dark.link-effect::before {
  background-color: #113f4c; }

a.text-flat-dark:hover, a.text-flat-dark:focus {
  color: black !important; }

.text-flat-darker {
  color: #0b2830 !important; }

a.text-flat-darker.link-effect::before {
  background-color: #0b2830; }

a.text-flat-darker:hover, a.text-flat-darker:focus {
  color: black !important; }

.text-flat-light {
  color: #acd2c5 !important; }

a.text-flat-light.link-effect::before {
  background-color: #acd2c5; }

a.text-flat-light:hover, a.text-flat-light:focus {
  color: #69ae97 !important; }

.text-flat-lighter {
  color: #cde4dc !important; }

a.text-flat-lighter.link-effect::before {
  background-color: #cde4dc; }

a.text-flat-lighter:hover, a.text-flat-lighter:focus {
  color: #8bc0ae !important; }

.bg-flat {
  background-color: #70b29c !important; }

a.bg-flat:hover, a.bg-flat:focus,
button.bg-flat:hover,
button.bg-flat:focus {
  background-color: #549b83 !important; }

.bg-flat-op {
  background-color: rgba(112, 178, 156, 0.75) !important; }

a.bg-flat-op:hover, a.bg-flat-op:focus,
button.bg-flat-op:hover,
button.bg-flat-op:focus {
  background-color: rgba(84, 155, 131, 0.75) !important; }

.bg-flat-dark {
  background-color: #113f4c !important; }

a.bg-flat-dark:hover, a.bg-flat-dark:focus,
button.bg-flat-dark:hover,
button.bg-flat-dark:focus {
  background-color: #081c22 !important; }

.bg-flat-dark-op {
  background-color: rgba(17, 63, 76, 0.83) !important; }

a.bg-flat-dark-op:hover, a.bg-flat-dark-op:focus,
button.bg-flat-dark-op:hover,
button.bg-flat-dark-op:focus {
  background-color: rgba(8, 28, 34, 0.83) !important; }

.bg-flat-darker {
  background-color: #0b2830 !important; }

a.bg-flat-darker:hover, a.bg-flat-darker:focus,
button.bg-flat-darker:hover,
button.bg-flat-darker:focus {
  background-color: #010507 !important; }

.bg-flat-light {
  background-color: #acd2c5 !important; }

a.bg-flat-light:hover, a.bg-flat-light:focus,
button.bg-flat-light:hover,
button.bg-flat-light:focus {
  background-color: #8bc0ae !important; }

.bg-flat-lighter {
  background-color: #cde4dc !important; }

a.bg-flat-lighter:hover, a.bg-flat-lighter:focus,
button.bg-flat-lighter:hover,
button.bg-flat-lighter:focus {
  background-color: #acd2c5 !important; }

.bg-gd-flat {
  background: #4b8b75;
  background: linear-gradient(135deg, #4b8b75 0%, #81bba7 100%) !important; }

.text-corporate {
  color: #2facb2 !important; }

a.text-corporate.link-effect::before {
  background-color: #2facb2; }

a.text-corporate:hover, a.text-corporate:focus {
  color: #1a5e61 !important; }

.text-corporate-dark {
  color: #323d4c !important; }

a.text-corporate-dark.link-effect::before {
  background-color: #323d4c; }

a.text-corporate-dark:hover, a.text-corporate-dark:focus {
  color: #0a0c0e !important; }

.text-corporate-darker {
  color: #252d38 !important; }

a.text-corporate-darker.link-effect::before {
  background-color: #252d38; }

a.text-corporate-darker:hover, a.text-corporate-darker:focus {
  color: black !important; }

.text-corporate-light {
  color: #5aced3 !important; }

a.text-corporate-light.link-effect::before {
  background-color: #5aced3; }

a.text-corporate-light:hover, a.text-corporate-light:focus {
  color: #2a999e !important; }

.text-corporate-lighter {
  color: #d3f2f3 !important; }

a.text-corporate-lighter.link-effect::before {
  background-color: #d3f2f3; }

a.text-corporate-lighter:hover, a.text-corporate-lighter:focus {
  color: #82dade !important; }

.bg-corporate {
  background-color: #2facb2 !important; }

a.bg-corporate:hover, a.bg-corporate:focus,
button.bg-corporate:hover,
button.bg-corporate:focus {
  background-color: #24858a !important; }

.bg-corporate-op {
  background-color: rgba(47, 172, 178, 0.75) !important; }

a.bg-corporate-op:hover, a.bg-corporate-op:focus,
button.bg-corporate-op:hover,
button.bg-corporate-op:focus {
  background-color: rgba(36, 133, 138, 0.75) !important; }

.bg-corporate-dark {
  background-color: #323d4c !important; }

a.bg-corporate-dark:hover, a.bg-corporate-dark:focus,
button.bg-corporate-dark:hover,
button.bg-corporate-dark:focus {
  background-color: #1e242d !important; }

.bg-corporate-dark-op {
  background-color: rgba(50, 61, 76, 0.83) !important; }

a.bg-corporate-dark-op:hover, a.bg-corporate-dark-op:focus,
button.bg-corporate-dark-op:hover,
button.bg-corporate-dark-op:focus {
  background-color: rgba(30, 36, 45, 0.83) !important; }

.bg-corporate-darker {
  background-color: #252d38 !important; }

a.bg-corporate-darker:hover, a.bg-corporate-darker:focus,
button.bg-corporate-darker:hover,
button.bg-corporate-darker:focus {
  background-color: #111419 !important; }

.bg-corporate-light {
  background-color: #5aced3 !important; }

a.bg-corporate-light:hover, a.bg-corporate-light:focus,
button.bg-corporate-light:hover,
button.bg-corporate-light:focus {
  background-color: #34bfc6 !important; }

.bg-corporate-lighter {
  background-color: #d3f2f3 !important; }

a.bg-corporate-lighter:hover, a.bg-corporate-lighter:focus,
button.bg-corporate-lighter:hover,
button.bg-corporate-lighter:focus {
  background-color: #abe6e9 !important; }

.bg-gd-corporate {
  background: #1f7275;
  background: linear-gradient(135deg, #1f7275 0%, #34bfc6 100%) !important; }

.text-earth {
  color: #6eab4d !important; }

a.text-earth.link-effect::before {
  background-color: #6eab4d; }

a.text-earth:hover, a.text-earth:focus {
  color: #41652d !important; }

.text-earth-dark {
  color: #424242 !important; }

a.text-earth-dark.link-effect::before {
  background-color: #424242; }

a.text-earth-dark:hover, a.text-earth-dark:focus {
  color: #0f0f0f !important; }

.text-earth-darker {
  color: #222 !important; }

a.text-earth-darker.link-effect::before {
  background-color: #222; }

a.text-earth-darker:hover, a.text-earth-darker:focus {
  color: black !important; }

.text-earth-light {
  color: #a0ca8a !important; }

a.text-earth-light.link-effect::before {
  background-color: #a0ca8a; }

a.text-earth-light:hover, a.text-earth-light:focus {
  color: #69a44a !important; }

.text-earth-lighter {
  color: #e4f0de !important; }

a.text-earth-lighter.link-effect::before {
  background-color: #e4f0de; }

a.text-earth-lighter:hover, a.text-earth-lighter:focus {
  color: #acd098 !important; }

.bg-earth {
  background-color: #6eab4d !important; }

a.bg-earth:hover, a.bg-earth:focus,
button.bg-earth:hover,
button.bg-earth:focus {
  background-color: #57883d !important; }

.bg-earth-op {
  background-color: rgba(110, 171, 77, 0.75) !important; }

a.bg-earth-op:hover, a.bg-earth-op:focus,
button.bg-earth-op:hover,
button.bg-earth-op:focus {
  background-color: rgba(87, 136, 61, 0.75) !important; }

.bg-earth-dark {
  background-color: #424242 !important; }

a.bg-earth-dark:hover, a.bg-earth-dark:focus,
button.bg-earth-dark:hover,
button.bg-earth-dark:focus {
  background-color: #292828 !important; }

.bg-earth-dark-op {
  background-color: rgba(66, 66, 66, 0.83) !important; }

a.bg-earth-dark-op:hover, a.bg-earth-dark-op:focus,
button.bg-earth-dark-op:hover,
button.bg-earth-dark-op:focus {
  background-color: rgba(41, 40, 40, 0.83) !important; }

.bg-earth-darker {
  background-color: #222 !important; }

a.bg-earth-darker:hover, a.bg-earth-darker:focus,
button.bg-earth-darker:hover,
button.bg-earth-darker:focus {
  background-color: #090909 !important; }

.bg-earth-light {
  background-color: #a0ca8a !important; }

a.bg-earth-light:hover, a.bg-earth-light:focus,
button.bg-earth-light:hover,
button.bg-earth-light:focus {
  background-color: #84ba66 !important; }

.bg-earth-lighter {
  background-color: #e4f0de !important; }

a.bg-earth-lighter:hover, a.bg-earth-lighter:focus,
button.bg-earth-lighter:hover,
button.bg-earth-lighter:focus {
  background-color: #c8e0bb !important; }

.bg-gd-earth {
  background: #4c7635;
  background: linear-gradient(135deg, #4c7635 0%, #7bb65c 100%) !important; }

.animated {
  animation-duration: 1.2s;
  animation-fill-mode: both; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.hinge {
  animation-duration: 2s; }

.animated.bounceIn,
.animated.bounceOut {
  animation-duration: .75s; }

.animated.flipOutX,
.animated.flipOutY {
  animation-duration: .75s; }

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0); }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0); }
  90% {
    transform: translate3d(0, -4px, 0); } }

.bounce {
  animation-name: bounce;
  transform-origin: center bottom; }

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1); }
  50% {
    transform: scale3d(1.05, 1.05, 1.05); }
  100% {
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-name: pulse; }

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  animation-name: rubberBand; }

@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0); } }

.shake {
  animation-name: shake; }

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    transform: rotate3d(0, 0, 1, -5deg); }
  100% {
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  transform-origin: top center;
  animation-name: swing; }

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  100% {
    transform: scale3d(1, 1, 1); } }

.tada {
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes wobble {
  0% {
    transform: none; }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    transform: none; } }

.wobble {
  animation-name: wobble; }

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97); }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  animation-name: bounceIn; }

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  100% {
    transform: none; } }

.bounceInDown {
  animation-name: bounceInDown; }

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  100% {
    transform: none; } }

.bounceInLeft {
  animation-name: bounceInLeft; }

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  100% {
    transform: none; } }

.bounceInRight {
  animation-name: bounceInRight; }

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  animation-name: bounceInUp; }

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  animation-name: bounceOut; }

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  animation-name: bounceOutDown; }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  animation-name: bounceOutLeft; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  animation-name: bounceOutRight; }

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  animation-name: bounceOutUp; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInDown {
  animation-name: fadeInDown; }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInDownBig {
  animation-name: fadeInDownBig; }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInLeft {
  animation-name: fadeInLeft; }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInLeftBig {
  animation-name: fadeInLeftBig; }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInRight {
  animation-name: fadeInRight; }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInRightBig {
  animation-name: fadeInRightBig; }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInUp {
  animation-name: fadeInUp; }

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInUpBig {
  animation-name: fadeInUpBig; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOutDown {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  animation-name: fadeOutDown; }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  animation-name: fadeOutDownBig; }

@keyframes fadeOutLeft {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  animation-name: fadeOutLeft; }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig; }

@keyframes fadeOutRight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  animation-name: fadeOutRight; }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  animation-name: fadeOutRightBig; }

@keyframes fadeOutUp {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  animation-name: fadeOutUp; }

@keyframes fadeOutUpBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  animation-name: fadeOutUpBig; }

@keyframes flip {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out; }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out; }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in; }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in; }
  100% {
    transform: perspective(400px);
    animation-timing-function: ease-in; } }

.animated.flip {
  backface-visibility: visible;
  animation-name: flip; }

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    transform: perspective(400px); } }

.flipInX {
  backface-visibility: visible !important;
  animation-name: flipInX; }

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    transform: perspective(400px); } }

.flipInY {
  backface-visibility: visible !important;
  animation-name: flipInY; }

@keyframes flipOutX {
  0% {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  100% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important; }

@keyframes flipOutY {
  0% {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  100% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  backface-visibility: visible !important;
  animation-name: flipOutY; }

@keyframes lightSpeedIn {
  0% {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    transform: skewX(-5deg);
    opacity: 1; }
  100% {
    transform: none;
    opacity: 1; } }

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out; }

@keyframes lightSpeedOut {
  0% {
    opacity: 1; }
  100% {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in; }

@keyframes rotateIn {
  0% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  100% {
    transform-origin: center;
    transform: none;
    opacity: 1; } }

.rotateIn {
  animation-name: rotateIn; }

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1; } }

.rotateInDownLeft {
  animation-name: rotateInDownLeft; }

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1; } }

.rotateInDownRight {
  animation-name: rotateInDownRight; }

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1; } }

.rotateInUpLeft {
  animation-name: rotateInUpLeft; }

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1; } }

.rotateInUpRight {
  animation-name: rotateInUpRight; }

@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1; }
  100% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  animation-name: rotateOut; }

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft; }

@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  animation-name: rotateOutDownRight; }

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft; }

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  animation-name: rotateOutUpRight; }

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  100% {
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  animation-name: hinge; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0); } }

.rollIn {
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  animation-name: rollOut; }

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  animation-name: zoomIn; }

@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  animation-name: zoomInDown; }

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  animation-name: zoomInLeft; }

@keyframes zoomInRight {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  animation-name: zoomInRight; }

@keyframes zoomInUp {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  animation-name: zoomInUp; }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

.zoomOut {
  animation-name: zoomOut; }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  animation-name: zoomOutDown; }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center; } }

.zoomOutLeft {
  animation-name: zoomOutLeft; }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center; } }

.zoomOutRight {
  animation-name: zoomOutRight; }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  animation-name: zoomOutUp; }

@keyframes slideInDown {
  0% {
    transform: translateY(-100%);
    visibility: visible; }
  100% {
    transform: translateY(0); } }

.slideInDown {
  animation-name: slideInDown; }

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    visibility: visible; }
  100% {
    transform: translateX(0); } }

.slideInLeft {
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible; }
  100% {
    transform: translateX(0); } }

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible; }
  100% {
    transform: translateX(0); } }

.slideInRight {
  animation-name: slideInRight; }

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    visibility: visible; }
  100% {
    transform: translateY(0); } }

.slideInUp {
  animation-name: slideInUp; }

@keyframes slideOutDown {
  0% {
    transform: translateY(0); }
  100% {
    visibility: hidden;
    transform: translateY(100%); } }

.slideOutDown {
  animation-name: slideOutDown; }

@keyframes slideOutLeft {
  0% {
    transform: translateX(0); }
  100% {
    visibility: hidden;
    transform: translateX(-100%); } }

.slideOutLeft {
  animation-name: slideOutLeft; }

@keyframes slideOutRight {
  0% {
    transform: translateX(0); }
  100% {
    visibility: hidden;
    transform: translateX(100%); } }

.slideOutRight {
  animation-name: slideOutRight; }

@keyframes slideOutUp {
  0% {
    transform: translateY(0); }
  100% {
    visibility: hidden;
    transform: translateY(-100%); } }

.slideOutUp {
  animation-name: slideOutUp; }

@font-face {
  font-family: "simple-line-icons";
  src: url("../fonts/Simple-Line-Icons.eot?v=2.4.0");
  src: url("../fonts/Simple-Line-Icons.eot?v=2.4.0#iefix") format("embedded-opentype"), url("../fonts/Simple-Line-Icons.woff2?v=2.4.0") format("woff2"), url("../fonts/Simple-Line-Icons.ttf?v=2.4.0") format("truetype"), url("../fonts/Simple-Line-Icons.woff?v=2.4.0") format("woff"), url("../fonts/Simple-Line-Icons.svg?v=2.4.0#simple-line-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.si {
  font-family: "simple-line-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.si-user:before {
  content: "\e005"; }

.si-people:before {
  content: "\e001"; }

.si-user-female:before {
  content: "\e000"; }

.si-user-follow:before {
  content: "\e002"; }

.si-user-following:before {
  content: "\e003"; }

.si-user-unfollow:before {
  content: "\e004"; }

.si-login:before {
  content: "\e066"; }

.si-logout:before {
  content: "\e065"; }

.si-emotsmile:before {
  content: "\e021"; }

.si-phone:before {
  content: "\e600"; }

.si-call-end:before {
  content: "\e048"; }

.si-call-in:before {
  content: "\e047"; }

.si-call-out:before {
  content: "\e046"; }

.si-map:before {
  content: "\e033"; }

.si-location-pin:before {
  content: "\e096"; }

.si-direction:before {
  content: "\e042"; }

.si-directions:before {
  content: "\e041"; }

.si-compass:before {
  content: "\e045"; }

.si-layers:before {
  content: "\e034"; }

.si-menu:before {
  content: "\e601"; }

.si-list:before {
  content: "\e067"; }

.si-options-vertical:before {
  content: "\e602"; }

.si-options:before {
  content: "\e603"; }

.si-arrow-down:before {
  content: "\e604"; }

.si-arrow-left:before {
  content: "\e605"; }

.si-arrow-right:before {
  content: "\e606"; }

.si-arrow-up:before {
  content: "\e607"; }

.si-arrow-up-circle:before {
  content: "\e078"; }

.si-arrow-left-circle:before {
  content: "\e07a"; }

.si-arrow-right-circle:before {
  content: "\e079"; }

.si-arrow-down-circle:before {
  content: "\e07b"; }

.si-check:before {
  content: "\e080"; }

.si-clock:before {
  content: "\e081"; }

.si-plus:before {
  content: "\e095"; }

.si-minus:before {
  content: "\e615"; }

.si-close:before {
  content: "\e082"; }

.si-event:before {
  content: "\e619"; }

.si-exclamation:before {
  content: "\e617"; }

.si-organization:before {
  content: "\e616"; }

.si-trophy:before {
  content: "\e006"; }

.si-screen-smartphone:before {
  content: "\e010"; }

.si-screen-desktop:before {
  content: "\e011"; }

.si-plane:before {
  content: "\e012"; }

.si-notebook:before {
  content: "\e013"; }

.si-mustache:before {
  content: "\e014"; }

.si-mouse:before {
  content: "\e015"; }

.si-magnet:before {
  content: "\e016"; }

.si-energy:before {
  content: "\e020"; }

.si-disc:before {
  content: "\e022"; }

.si-cursor:before {
  content: "\e06e"; }

.si-cursor-move:before {
  content: "\e023"; }

.si-crop:before {
  content: "\e024"; }

.si-chemistry:before {
  content: "\e026"; }

.si-speedometer:before {
  content: "\e007"; }

.si-shield:before {
  content: "\e00e"; }

.si-screen-tablet:before {
  content: "\e00f"; }

.si-magic-wand:before {
  content: "\e017"; }

.si-hourglass:before {
  content: "\e018"; }

.si-graduation:before {
  content: "\e019"; }

.si-ghost:before {
  content: "\e01a"; }

.si-game-controller:before {
  content: "\e01b"; }

.si-fire:before {
  content: "\e01c"; }

.si-eyeglass:before {
  content: "\e01d"; }

.si-envelope-open:before {
  content: "\e01e"; }

.si-envelope-letter:before {
  content: "\e01f"; }

.si-bell:before {
  content: "\e027"; }

.si-badge:before {
  content: "\e028"; }

.si-anchor:before {
  content: "\e029"; }

.si-wallet:before {
  content: "\e02a"; }

.si-vector:before {
  content: "\e02b"; }

.si-speech:before {
  content: "\e02c"; }

.si-puzzle:before {
  content: "\e02d"; }

.si-printer:before {
  content: "\e02e"; }

.si-present:before {
  content: "\e02f"; }

.si-playlist:before {
  content: "\e030"; }

.si-pin:before {
  content: "\e031"; }

.si-picture:before {
  content: "\e032"; }

.si-handbag:before {
  content: "\e035"; }

.si-globe-alt:before {
  content: "\e036"; }

.si-globe:before {
  content: "\e037"; }

.si-folder-alt:before {
  content: "\e039"; }

.si-folder:before {
  content: "\e089"; }

.si-film:before {
  content: "\e03a"; }

.si-feed:before {
  content: "\e03b"; }

.si-drop:before {
  content: "\e03e"; }

.si-drawer:before {
  content: "\e03f"; }

.si-docs:before {
  content: "\e040"; }

.si-doc:before {
  content: "\e085"; }

.si-diamond:before {
  content: "\e043"; }

.si-cup:before {
  content: "\e044"; }

.si-calculator:before {
  content: "\e049"; }

.si-bubbles:before {
  content: "\e04a"; }

.si-briefcase:before {
  content: "\e04b"; }

.si-book-open:before {
  content: "\e04c"; }

.si-basket-loaded:before {
  content: "\e04d"; }

.si-basket:before {
  content: "\e04e"; }

.si-bag:before {
  content: "\e04f"; }

.si-action-undo:before {
  content: "\e050"; }

.si-action-redo:before {
  content: "\e051"; }

.si-wrench:before {
  content: "\e052"; }

.si-umbrella:before {
  content: "\e053"; }

.si-trash:before {
  content: "\e054"; }

.si-tag:before {
  content: "\e055"; }

.si-support:before {
  content: "\e056"; }

.si-frame:before {
  content: "\e038"; }

.si-size-fullscreen:before {
  content: "\e057"; }

.si-size-actual:before {
  content: "\e058"; }

.si-shuffle:before {
  content: "\e059"; }

.si-share-alt:before {
  content: "\e05a"; }

.si-share:before {
  content: "\e05b"; }

.si-rocket:before {
  content: "\e05c"; }

.si-question:before {
  content: "\e05d"; }

.si-pie-chart:before {
  content: "\e05e"; }

.si-pencil:before {
  content: "\e05f"; }

.si-note:before {
  content: "\e060"; }

.si-loop:before {
  content: "\e064"; }

.si-home:before {
  content: "\e069"; }

.si-grid:before {
  content: "\e06a"; }

.si-graph:before {
  content: "\e06b"; }

.si-microphone:before {
  content: "\e063"; }

.si-music-tone-alt:before {
  content: "\e061"; }

.si-music-tone:before {
  content: "\e062"; }

.si-earphones-alt:before {
  content: "\e03c"; }

.si-earphones:before {
  content: "\e03d"; }

.si-equalizer:before {
  content: "\e06c"; }

.si-like:before {
  content: "\e068"; }

.si-dislike:before {
  content: "\e06d"; }

.si-control-start:before {
  content: "\e06f"; }

.si-control-rewind:before {
  content: "\e070"; }

.si-control-play:before {
  content: "\e071"; }

.si-control-pause:before {
  content: "\e072"; }

.si-control-forward:before {
  content: "\e073"; }

.si-control-end:before {
  content: "\e074"; }

.si-volume-1:before {
  content: "\e09f"; }

.si-volume-2:before {
  content: "\e0a0"; }

.si-volume-off:before {
  content: "\e0a1"; }

.si-calendar:before {
  content: "\e075"; }

.si-bulb:before {
  content: "\e076"; }

.si-chart:before {
  content: "\e077"; }

.si-ban:before {
  content: "\e07c"; }

.si-bubble:before {
  content: "\e07d"; }

.si-camrecorder:before {
  content: "\e07e"; }

.si-camera:before {
  content: "\e07f"; }

.si-cloud-download:before {
  content: "\e083"; }

.si-cloud-upload:before {
  content: "\e084"; }

.si-envelope:before {
  content: "\e086"; }

.si-eye:before {
  content: "\e087"; }

.si-flag:before {
  content: "\e088"; }

.si-heart:before {
  content: "\e08a"; }

.si-info:before {
  content: "\e08b"; }

.si-key:before {
  content: "\e08c"; }

.si-link:before {
  content: "\e08d"; }

.si-lock:before {
  content: "\e08e"; }

.si-lock-open:before {
  content: "\e08f"; }

.si-magnifier:before {
  content: "\e090"; }

.si-magnifier-add:before {
  content: "\e091"; }

.si-magnifier-remove:before {
  content: "\e092"; }

.si-paper-clip:before {
  content: "\e093"; }

.si-paper-plane:before {
  content: "\e094"; }

.si-power:before {
  content: "\e097"; }

.si-refresh:before {
  content: "\e098"; }

.si-reload:before {
  content: "\e099"; }

.si-settings:before {
  content: "\e09a"; }

.si-star:before {
  content: "\e09b"; }

.si-symbol-female:before {
  content: "\e09c"; }

.si-symbol-male:before {
  content: "\e09d"; }

.si-target:before {
  content: "\e09e"; }

.si-credit-card:before {
  content: "\e025"; }

.si-paypal:before {
  content: "\e608"; }

.si-social-tumblr:before {
  content: "\e00a"; }

.si-social-twitter:before {
  content: "\e009"; }

.si-social-facebook:before {
  content: "\e00b"; }

.si-social-instagram:before {
  content: "\e609"; }

.si-social-linkedin:before {
  content: "\e60a"; }

.si-social-pinterest:before {
  content: "\e60b"; }

.si-social-github:before {
  content: "\e60c"; }

.si-social-google:before {
  content: "\e60d"; }

.si-social-reddit:before {
  content: "\e60e"; }

.si-social-skype:before {
  content: "\e60f"; }

.si-social-dribbble:before {
  content: "\e00d"; }

.si-social-behance:before {
  content: "\e610"; }

.si-social-foursqare:before {
  content: "\e611"; }

.si-social-soundcloud:before {
  content: "\e612"; }

.si-social-spotify:before {
  content: "\e613"; }

.si-social-stumbleupon:before {
  content: "\e614"; }

.si-social-youtube:before {
  content: "\e008"; }

.si-social-dropbox:before {
  content: "\e00c"; }

.si-social-vkontakte:before {
  content: "\e618"; }

.si-social-steam:before {
  content: "\e620"; }

.si-users:before {
  content: "\e001"; }

.si-bar-chart:before {
  content: "\e077"; }

.si-camcorder:before {
  content: "\e07e"; }

.si-emoticon-smile:before {
  content: "\e021"; }

.si-eyeglasses:before {
  content: "\e01d"; }

.si-moustache:before {
  content: "\e014"; }

.si-pointer:before {
  content: "\e096"; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("../fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("../fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-fw {
  width: 1.28571429em;
  text-align: center; }

.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.fa-ul > li {
  position: relative; }

.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 4px;
  text-align: center; }

.fa-li.fa-lg {
  left: -1.85714286em; }

.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right {
  margin-left: .3em; }

/* Deprecated as of 4.4.0 */
.pull-right {
  float: right; }

.pull-left {
  float: left; }

.fa.pull-left {
  margin-right: .3em; }

.fa.pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none; }

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #ffffff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "\f000"; }

.fa-music:before {
  content: "\f001"; }

.fa-search:before {
  content: "\f002"; }

.fa-envelope-o:before {
  content: "\f003"; }

.fa-heart:before {
  content: "\f004"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-o:before {
  content: "\f006"; }

.fa-user:before {
  content: "\f007"; }

.fa-film:before {
  content: "\f008"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-check:before {
  content: "\f00c"; }

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-signal:before {
  content: "\f012"; }

.fa-gear:before,
.fa-cog:before {
  content: "\f013"; }

.fa-trash-o:before {
  content: "\f014"; }

.fa-home:before {
  content: "\f015"; }

.fa-file-o:before {
  content: "\f016"; }

.fa-clock-o:before {
  content: "\f017"; }

.fa-road:before {
  content: "\f018"; }

.fa-download:before {
  content: "\f019"; }

.fa-arrow-circle-o-down:before {
  content: "\f01a"; }

.fa-arrow-circle-o-up:before {
  content: "\f01b"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-play-circle-o:before {
  content: "\f01d"; }

.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e"; }

.fa-refresh:before {
  content: "\f021"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-lock:before {
  content: "\f023"; }

.fa-flag:before {
  content: "\f024"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-print:before {
  content: "\f02f"; }

.fa-camera:before {
  content: "\f030"; }

.fa-font:before {
  content: "\f031"; }

.fa-bold:before {
  content: "\f032"; }

.fa-italic:before {
  content: "\f033"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-list:before {
  content: "\f03a"; }

.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-video-camera:before {
  content: "\f03d"; }

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e"; }

.fa-pencil:before {
  content: "\f040"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-tint:before {
  content: "\f043"; }

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044"; }

.fa-share-square-o:before {
  content: "\f045"; }

.fa-check-square-o:before {
  content: "\f046"; }

.fa-arrows:before {
  content: "\f047"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-play:before {
  content: "\f04b"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-eject:before {
  content: "\f052"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-times-circle-o:before {
  content: "\f05c"; }

.fa-check-circle-o:before {
  content: "\f05d"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-mail-forward:before,
.fa-share:before {
  content: "\f064"; }

.fa-expand:before {
  content: "\f065"; }

.fa-compress:before {
  content: "\f066"; }

.fa-plus:before {
  content: "\f067"; }

.fa-minus:before {
  content: "\f068"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-plane:before {
  content: "\f072"; }

.fa-calendar:before {
  content: "\f073"; }

.fa-random:before {
  content: "\f074"; }

.fa-comment:before {
  content: "\f075"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-arrows-v:before {
  content: "\f07d"; }

.fa-arrows-h:before {
  content: "\f07e"; }

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-key:before {
  content: "\f084"; }

.fa-gears:before,
.fa-cogs:before {
  content: "\f085"; }

.fa-comments:before {
  content: "\f086"; }

.fa-thumbs-o-up:before {
  content: "\f087"; }

.fa-thumbs-o-down:before {
  content: "\f088"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-heart-o:before {
  content: "\f08a"; }

.fa-sign-out:before {
  content: "\f08b"; }

.fa-linkedin-square:before {
  content: "\f08c"; }

.fa-thumb-tack:before {
  content: "\f08d"; }

.fa-external-link:before {
  content: "\f08e"; }

.fa-sign-in:before {
  content: "\f090"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-upload:before {
  content: "\f093"; }

.fa-lemon-o:before {
  content: "\f094"; }

.fa-phone:before {
  content: "\f095"; }

.fa-square-o:before {
  content: "\f096"; }

.fa-bookmark-o:before {
  content: "\f097"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a"; }

.fa-github:before {
  content: "\f09b"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-feed:before,
.fa-rss:before {
  content: "\f09e"; }

.fa-hdd-o:before {
  content: "\f0a0"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-hand-o-right:before {
  content: "\f0a4"; }

.fa-hand-o-left:before {
  content: "\f0a5"; }

.fa-hand-o-up:before {
  content: "\f0a6"; }

.fa-hand-o-down:before {
  content: "\f0a7"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-group:before,
.fa-users:before {
  content: "\f0c0"; }

.fa-chain:before,
.fa-link:before {
  content: "\f0c1"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4"; }

.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-plus:before {
  content: "\f0d5"; }

.fa-money:before {
  content: "\f0d6"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd"; }

.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-linkedin:before {
  content: "\f0e1"; }

.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2"; }

.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3"; }

.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4"; }

.fa-comment-o:before {
  content: "\f0e5"; }

.fa-comments-o:before {
  content: "\f0e6"; }

.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea"; }

.fa-lightbulb-o:before {
  content: "\f0eb"; }

.fa-exchange:before {
  content: "\f0ec"; }

.fa-cloud-download:before {
  content: "\f0ed"; }

.fa-cloud-upload:before {
  content: "\f0ee"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-bell-o:before {
  content: "\f0a2"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-cutlery:before {
  content: "\f0f5"; }

.fa-file-text-o:before {
  content: "\f0f6"; }

.fa-building-o:before {
  content: "\f0f7"; }

.fa-hospital-o:before {
  content: "\f0f8"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b"; }

.fa-circle-o:before {
  content: "\f10c"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-circle:before {
  content: "\f111"; }

.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-folder-o:before {
  content: "\f114"; }

.fa-folder-open-o:before {
  content: "\f115"; }

.fa-smile-o:before {
  content: "\f118"; }

.fa-frown-o:before {
  content: "\f119"; }

.fa-meh-o:before {
  content: "\f11a"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-keyboard-o:before {
  content: "\f11c"; }

.fa-flag-o:before {
  content: "\f11d"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-code:before {
  content: "\f121"; }

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122"; }

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-crop:before {
  content: "\f125"; }

.fa-code-fork:before {
  content: "\f126"; }

.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127"; }

.fa-question:before {
  content: "\f128"; }

.fa-info:before {
  content: "\f129"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-shield:before {
  content: "\f132"; }

.fa-calendar-o:before {
  content: "\f133"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-minus-square-o:before {
  content: "\f147"; }

.fa-level-up:before {
  content: "\f148"; }

.fa-level-down:before {
  content: "\f149"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-pencil-square:before {
  content: "\f14b"; }

.fa-external-link-square:before {
  content: "\f14c"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150"; }

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151"; }

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152"; }

.fa-euro:before,
.fa-eur:before {
  content: "\f153"; }

.fa-gbp:before {
  content: "\f154"; }

.fa-dollar:before,
.fa-usd:before {
  content: "\f155"; }

.fa-rupee:before,
.fa-inr:before {
  content: "\f156"; }

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157"; }

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158"; }

.fa-won:before,
.fa-krw:before {
  content: "\f159"; }

.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-text:before {
  content: "\f15c"; }

.fa-sort-alpha-asc:before {
  content: "\f15d"; }

.fa-sort-alpha-desc:before {
  content: "\f15e"; }

.fa-sort-amount-asc:before {
  content: "\f160"; }

.fa-sort-amount-desc:before {
  content: "\f161"; }

.fa-sort-numeric-asc:before {
  content: "\f162"; }

.fa-sort-numeric-desc:before {
  content: "\f163"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-youtube-square:before {
  content: "\f166"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-youtube-play:before {
  content: "\f16a"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-adn:before {
  content: "\f170"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitbucket-square:before {
  content: "\f172"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-up:before {
  content: "\f176"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }

.fa-apple:before {
  content: "\f179"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-android:before {
  content: "\f17b"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-trello:before {
  content: "\f181"; }

.fa-female:before {
  content: "\f182"; }

.fa-male:before {
  content: "\f183"; }

.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184"; }

.fa-sun-o:before {
  content: "\f185"; }

.fa-moon-o:before {
  content: "\f186"; }

.fa-archive:before {
  content: "\f187"; }

.fa-bug:before {
  content: "\f188"; }

.fa-vk:before {
  content: "\f189"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-arrow-circle-o-right:before {
  content: "\f18e"; }

.fa-arrow-circle-o-left:before {
  content: "\f190"; }

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191"; }

.fa-dot-circle-o:before {
  content: "\f192"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195"; }

.fa-plus-square-o:before {
  content: "\f196"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-slack:before {
  content: "\f198"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c"; }

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-spoon:before {
  content: "\f1b1"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-automobile:before,
.fa-car:before {
  content: "\f1b9"; }

.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-file-pdf-o:before {
  content: "\f1c1"; }

.fa-file-word-o:before {
  content: "\f1c2"; }

.fa-file-excel-o:before {
  content: "\f1c3"; }

.fa-file-powerpoint-o:before {
  content: "\f1c4"; }

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5"; }

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6"; }

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7"; }

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8"; }

.fa-file-code-o:before {
  content: "\f1c9"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd"; }

.fa-circle-o-notch:before {
  content: "\f1ce"; }

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: "\f1d0"; }

.fa-ge:before,
.fa-empire:before {
  content: "\f1d1"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7"; }

.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9"; }

.fa-history:before {
  content: "\f1da"; }

.fa-circle-thin:before {
  content: "\f1db"; }

.fa-header:before {
  content: "\f1dc"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-sliders:before {
  content: "\f1de"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-newspaper-o:before {
  content: "\f1ea"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bell-slash-o:before {
  content: "\f1f7"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-eyedropper:before {
  content: "\f1fb"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-area-chart:before {
  content: "\f1fe"; }

.fa-pie-chart:before {
  content: "\f200"; }

.fa-line-chart:before {
  content: "\f201"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-bus:before {
  content: "\f207"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-cc:before {
  content: "\f20a"; }

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b"; }

.fa-meanpath:before {
  content: "\f20c"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-diamond:before {
  content: "\f219"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-venus:before {
  content: "\f221"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-intersex:before,
.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-facebook-official:before {
  content: "\f230"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-server:before {
  content: "\f233"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-hotel:before,
.fa-bed:before {
  content: "\f236"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-train:before {
  content: "\f238"; }

.fa-subway:before {
  content: "\f239"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-yc:before,
.fa-y-combinator:before {
  content: "\f23b"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-sticky-note-o:before {
  content: "\f24a"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-hourglass-o:before {
  content: "\f250"; }

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251"; }

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255"; }

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256"; }

.fa-hand-scissors-o:before {
  content: "\f257"; }

.fa-hand-lizard-o:before {
  content: "\f258"; }

.fa-hand-spock-o:before {
  content: "\f259"; }

.fa-hand-pointer-o:before {
  content: "\f25a"; }

.fa-hand-peace-o:before {
  content: "\f25b"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-safari:before {
  content: "\f267"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-tv:before,
.fa-television:before {
  content: "\f26c"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-500px:before {
  content: "\f26e"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-calendar-plus-o:before {
  content: "\f271"; }

.fa-calendar-minus-o:before {
  content: "\f272"; }

.fa-calendar-times-o:before {
  content: "\f273"; }

.fa-calendar-check-o:before {
  content: "\f274"; }

.fa-industry:before {
  content: "\f275"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-map-o:before {
  content: "\f278"; }

.fa-map:before {
  content: "\f279"; }

.fa-commenting:before {
  content: "\f27a"; }

.fa-commenting-o:before {
  content: "\f27b"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-vimeo:before {
  content: "\f27d"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-edge:before {
  content: "\f282"; }

.fa-credit-card-alt:before {
  content: "\f283"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-modx:before {
  content: "\f285"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-usb:before {
  content: "\f287"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-pause-circle-o:before {
  content: "\f28c"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stop-circle-o:before {
  content: "\f28e"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-percent:before {
  content: "\f295"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-envira:before {
  content: "\f299"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-wheelchair-alt:before {
  content: "\f29b"; }

.fa-question-circle-o:before {
  content: "\f29c"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-volume-control-phone:before {
  content: "\f2a0"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: "\f2a4"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-signing:before,
.fa-sign-language:before {
  content: "\f2a7"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\f2b3"; }

.fa-fa:before,
.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-handshake-o:before {
  content: "\f2b5"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-open-o:before {
  content: "\f2b7"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-book-o:before {
  content: "\f2ba"; }

.fa-vcard:before,
.fa-address-card:before {
  content: "\f2bb"; }

.fa-vcard-o:before,
.fa-address-card-o:before {
  content: "\f2bc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-circle-o:before {
  content: "\f2be"; }

.fa-user-o:before {
  content: "\f2c0"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-drivers-license:before,
.fa-id-card:before {
  content: "\f2c2"; }

.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "\f2c3"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: "\f2cd"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-times-rectangle:before,
.fa-window-close:before {
  content: "\f2d3"; }

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "\f2d4"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-eercast:before {
  content: "\f2da"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-snowflake-o:before {
  content: "\f2dc"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-meetup:before {
  content: "\f2e0"; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

.datepicker {
  z-index: 1051 !important; }

.input-daterange .input-group-addon {
  padding: 4px 12px;
  margin: 0; }

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #faeab9;
  border-color: #faeab9; }

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #3f9ce8;
  border-color: #3f9ce8; }

.dropzone {
  min-height: 200px;
  background-color: #f6f7f9;
  border: 2px dotted #d4dae3;
  border-radius: 3px; }
  .dropzone .dz-message {
    margin: 65px 0;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    color: #575757; }
  .dropzone:hover {
    background-color: #fff;
    border-color: #3f9ce8; }
    .dropzone:hover .dz-message {
      color: #3f9ce8; }

table.dataTable {
  border-collapse: collapse !important; }
  table.dataTable td,
  table.dataTable th {
    box-sizing: border-box; }
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 20px; }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    display: none; }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    top: 10px;
    right: 6px;
    bottom: auto;
    font-family: FontAwesome;
    font-size: 14px;
    opacity: .6; }
  table.dataTable thead .sorting:after {
    content: "\f0dc"; }
  table.dataTable thead .sorting_asc:after {
    content: "\f106"; }
  table.dataTable thead .sorting_desc:after {
    content: "\f107"; }

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin-top: 10px;
  justify-content: center; }
  @media (min-width: 768px) {
    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
      margin-top: 2px;
      justify-content: flex-end; } }

.pie-chart {
  position: relative;
  display: block; }
  .pie-chart > canvas {
    display: block;
    margin: 0 auto; }
  .pie-chart > span {
    position: absolute;
    top: 50%;
    right: auto;
    left: auto;
    transform: translateX(0) translateY(-50%);
    right: 0;
    left: 0;
    text-align: center; }

.flot-tooltip {
  position: absolute;
  display: none;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  padding: 4px 8px; }

.flot-pie-label {
  font-size: 13px;
  text-align: center;
  padding: 4px 8px;
  color: #fff; }

.legend > table td {
  padding: 3px 4px;
  font-size: 14px; }

.fc-event {
  padding-left: 4px;
  padding-right: 2px;
  font-weight: 600;
  line-height: 1.4;
  color: #171717;
  border: 1px solid #e3f4fc;
  border-radius: 0; }
  .fc-event:hover {
    color: #575757; }

.fc-event,
.fc-event-dot {
  background-color: #e3f4fc; }

.fc button {
  height: 34px;
  line-height: 34px;
  font-weight: 600;
  cursor: default; }

.fc-state-default.fc-corner-left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.fc-state-default.fc-corner-right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.fc-state-default {
  color: #171717;
  background-color: #f0f2f5;
  background-image: none;
  border: 1px solid #d4dae3;
  text-shadow: none;
  box-shadow: none; }

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #343a40;
  background-color: #cbd2dd;
  border-color: #adb8c8; }

.fc-state-hover {
  color: #171717;
  transition: all .15s ease-out; }

.fc-state-down,
.fc-state-active {
  background-color: #cbd2dd;
  border-color: #adb8c8;
  box-shadow: none; }

.fc-state-disabled {
  opacity: .35; }

.fc-toolbar {
  margin-bottom: 29px; }
  @media (max-width: 767.98px) {
    .fc-toolbar > div {
      display: block !important;
      float: none !important;
      margin-bottom: 12px !important; }
      .fc-toolbar > div::after {
        display: block;
        clear: both;
        content: ""; } }

.fc thead th.fc-widget-header {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #f6f7f9; }

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: #e4e7ed; }

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
  background: #f0f2f5; }

.fc-unthemed .fc-today {
  background: #f6f7f9; }

@media (max-width: 575.98px) {
  .fc-header-toolbar .fc-button {
    margin-top: 5px;
    margin-bottom: 5px; } }

pre.pre-sh {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  border-radius: 0; }
  pre.pre-sh code.hljs {
    background: transparent; }
  pre.pre-sh code.pwrap {
    white-space: pre-wrap; }

.irs-line {
  height: 5px;
  background: #f0f2f5;
  border: none;
  border-radius: 3px; }

.irs-bar {
  height: 5px;
  border: none;
  background: #3f9ce8; }
  .irs-bar-edge {
    height: 5px;
    border: none;
    background: #3f9ce8;
    border-radius: 3px 0 0 3px; }

.irs-slider {
  top: 25px;
  width: 20px;
  height: 20px;
  border: none;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35); }
  .irs-slider:hover {
    background: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.75); }

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 13px;
  background: #3f9ce8; }

.irs-grid-pol {
  background: #3f9ce8; }

.autocomplete-suggestions {
  border-color: #e4e7ed;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.05); }

.autocomplete-suggestion {
  padding: 8px 14px;
  color: #575757;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5; }
  .autocomplete-suggestion b {
    color: #3f9ce8;
    font-weight: 600; }
  .autocomplete-suggestion.selected {
    background-color: #f0f2f5; }

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: none !important;
  background-color: rgba(0, 0, 0, 0.75) !important; }

div.tagsinput {
  padding: 5px 12px 1px;
  border-color: #d4dae3;
  border-radius: 3px; }
  div.tagsinput input {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 2px;
    height: 22px; }
  .form-material div.tagsinput {
    padding-right: 0;
    padding-left: 0;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #d4dae3; }
  div.tagsinput span.tag {
    padding: 2px 5px;
    margin-bottom: 2px;
    height: 22px;
    line-height: 18px;
    color: #fff;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600;
    background-color: #3f9ce8;
    border: none; }
    div.tagsinput span.tag a {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.5); }
      div.tagsinput span.tag a:hover {
        color: rgba(255, 255, 255, 0.75); }

.draggable-column {
  min-height: 100px; }

.draggable-handler {
  cursor: move; }

.draggable-placeholder {
  background-color: #edeff3;
  border: 1px dashed #c5cdd8; }

.jvectormap-tip {
  padding: 6px 8px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #212529;
  border: none;
  border-radius: 0; }

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  left: 15px;
  padding: 4px;
  line-height: 15px;
  background: #555; }

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 15px;
  height: 15px; }

.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover {
  opacity: .75; }

.jvectormap-zoomout {
  top: 40px; }

.select2-container .select2-selection--single {
  height: 34px; }

.select2-container .select2-dropdown {
  border-color: #d4dae3;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.select2-container--default .select2-selection--single {
  border-color: #d4dae3;
  border-radius: 3px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: 12px;
    line-height: 34px; }
    .form-material .select2-container--default .select2-selection--single .select2-selection__rendered {
      padding-left: 0; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 34px; }
  .form-material .select2-container--default .select2-selection--single {
    border: none;
    border-bottom: 1px solid #d4dae3;
    border-radius: 0; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #6c757d; }

.select2-container--default .select2-selection--multiple {
  border-color: #d4dae3;
  border-radius: 3px;
  min-height: 34px; }
  .form-material .select2-container--default .select2-selection--multiple {
    border: none;
    border-bottom: 1px solid #d4dae3;
    border-radius: 0; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding-right: 12px;
    padding-left: 12px; }
    .form-material .select2-container--default .select2-selection--multiple .select2-selection__rendered {
      padding-left: 0; }

.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--single {
  border-color: #97a5ba; }
  .form-material .select2-container--default.select2-container--focus .select2-selection--multiple, .form-material
  .select2-container--default.select2-container--focus .select2-selection--single {
    border-bottom-color: #97a5ba; }

.is-valid .select2-container--default .select2-selection--single,
.is-valid .select2-container--default .select2-selection--multiple,
.is-valid .select2-container--default.select2-container--focus .select2-selection--single,
.is-valid .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #9ccc65; }

.is-valid .form-control .select2-container--default .select2-selection--single,
.is-valid .form-control .select2-container--default .select2-selection--multiple,
.is-valid .form-control .select2-container--default.select2-container--focus .select2-selection--single,
.is-valid .form-control .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-bottom-color: #9ccc65; }

.is-invalid .select2-container--default .select2-selection--single,
.is-invalid .select2-container--default .select2-selection--multiple,
.is-invalid .select2-container--default.select2-container--focus .select2-selection--single,
.is-invalid .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #ef5350; }

.is-invalid .form-material .select2-container--default .select2-selection--single,
.is-invalid .form-material .select2-container--default .select2-selection--multiple,
.is-invalid .form-material .select2-container--default.select2-container--focus .select2-selection--single,
.is-invalid .form-material .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-bottom-color: #ef5350; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  height: 22px;
  line-height: 22px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  background-color: #3f9ce8;
  border: none;
  border-radius: 3px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.5); }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: rgba(255, 255, 255, 0.75); }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #d4dae3; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #3f9ce8; }

.select2-container--default .select2-search--inline .select2-search__field {
  padding-right: 0;
  padding-left: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  box-shadow: none; }
  .form-material .select2-container--default .select2-search--inline .select2-search__field {
    padding-left: 0; }

.select2-search--dropdown .select2-search__field {
  padding: 6px 12px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 3px;
  box-shadow: none; }

.CodeMirror-fullscreen,
.editor-toolbar.fullscreen,
.editor-preview-side {
  z-index: 1052; }

.editor-preview {
  z-index: 1051; }

.slick-slider.slick-dotted {
  margin-bottom: 50px; }
  .slick-slider.slick-dotted .slick-dots {
    bottom: -30px; }

.slick-slider.slick-dotted.slick-dotted-inner {
  margin-bottom: 0; }
  .slick-slider.slick-dotted.slick-dotted-inner .slick-dots {
    bottom: 10px; }

.slick-slider.slick-dotted.slick-dotted-white .slick-dots li button::before {
  color: #fff; }

.slick-slider .slick-prev,
.slick-slider .slick-next {
  width: 40px;
  height: 60px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.03);
  z-index: 2; }
  .slick-slider .slick-prev:hover,
  .slick-slider .slick-next:hover {
    background-color: rgba(0, 0, 0, 0.15); }
  .slick-slider .slick-prev::before,
  .slick-slider .slick-next::before {
    font-family: 'FontAwesome';
    font-size: 28px;
    line-height: 28px;
    color: #343a40; }

.slick-slider .slick-prev {
  left: 0; }
  .slick-slider .slick-prev::before {
    content: '\f104'; }

.slick-slider .slick-next {
  right: 0; }
  .slick-slider .slick-next::before {
    content: '\f105'; }

.slick-slider.slick-nav-white .slick-prev,
.slick-slider.slick-nav-white .slick-next {
  background-color: rgba(255, 255, 255, 0.5); }
  .slick-slider.slick-nav-white .slick-prev:hover,
  .slick-slider.slick-nav-white .slick-next:hover {
    background-color: white; }
  .slick-slider.slick-nav-white .slick-prev:before,
  .slick-slider.slick-nav-white .slick-next:before {
    color: #000; }

.slick-slider.slick-nav-black .slick-prev,
.slick-slider.slick-nav-black .slick-next {
  background-color: rgba(0, 0, 0, 0.25); }
  .slick-slider.slick-nav-black .slick-prev:hover,
  .slick-slider.slick-nav-black .slick-next:hover {
    background-color: black; }
  .slick-slider.slick-nav-black .slick-prev::before,
  .slick-slider.slick-nav-black .slick-next::before {
    color: #fff; }

.slick-slider.slick-nav-hover .slick-prev,
.slick-slider.slick-nav-hover .slick-next {
  opacity: 0;
  transition: opacity .25s ease-out; }

.slick-slider.slick-nav-hover:hover .slick-prev,
.slick-slider.slick-nav-hover:hover .slick-next {
  opacity: 1; }

.note-editor.note-frame .note-toolbar-wrapper {
  background-color: #fff; }

.note-editor.note-frame .note-toolbar {
  background-color: #fcfcfc !important; }

.note-editor.note-frame.note-frame {
  border-color: #ddd; }

.note-editor.note-frame .note-toolbar {
  border-bottom-color: #ddd; }

.note-editor.note-frame .note-statusbar .note-resizebar {
  border-top-color: #ddd; }

.note-editor.note-frame .note-popover .popover-content .note-color .dropdown-menu,
.note-editor.note-frame .card-header.note-toolbar .note-color .dropdown-menu {
  min-width: 350px; }
