//
// Ribbon
// --------------------------------------------------

@mixin ribbon-variation($color) {
    .ribbon-box {
        background-color: $color;
    }

    &.ribbon-bookmark {
        .ribbon-box::before {
            border-color: $color;
            border-left-color: transparent;
        }

        &.ribbon-left .ribbon-box::before {
            border-color: $color;
            border-right-color: transparent;
        }
    }

    &.ribbon-modern {
        .ribbon-box::before {
            border-color: $color;
            border-left-color: transparent;
            border-bottom-color: transparent;
        }

        &.ribbon-bottom .ribbon-box::before {
            border-color: $color;
            border-top-color: transparent;
            border-left-color: transparent;
        }

        &.ribbon-left .ribbon-box::before {
            border-color: $color;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }

        &.ribbon-left.ribbon-bottom .ribbon-box::before {
            border-color: $color;
            border-top-color: transparent;
            border-right-color: transparent;
        }
    }
}
