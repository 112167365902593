//
// Summernote
//
// Overwrite/Extend styles
// --------------------------------------------------

.note-editor.note-frame {
    .note-toolbar-wrapper {
        background-color: $white;
    }

    .note-toolbar {
        background-color: #fcfcfc !important;
    }

    &.note-frame {
        border-color: #ddd;
    }

    .note-toolbar {
        border-bottom-color: #ddd;
    }

    .note-statusbar .note-resizebar {
        border-top-color: #ddd;
    }

    .note-popover .popover-content .note-color .dropdown-menu,
    .card-header.note-toolbar .note-color .dropdown-menu {
        min-width: 350px;
    }
}